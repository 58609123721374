import React, { PropsWithChildren, ReactNode, useCallback, useEffect, useRef } from 'react';
import { Animated, Easing, Platform, View } from 'react-native';

import {
    DefaultTheme,
    LinkingOptions,
    NavigationContainer,
    getStateFromPath,
    useNavigationContainerRef,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import { enableDataDogNavTracking } from '@/data/datadog';
import { AccountStack } from '@/feature/account-details/AccountStack';
import { EmailUpdate } from '@/feature/account-details/screens/EmailUpdate';
import { EmailUpdateConfirm } from '@/feature/account-details/screens/EmailUpdateConfirm';
import { GamingLimitsScreen } from '@/feature/account-details/screens/GamingLimits/GamingLimits';
import { ManageDebitCardExpiryUpdate } from '@/feature/account-details/screens/ManageDebitCardExpiryUpdate';
import { ManageDebitCards } from '@/feature/account-details/screens/ManageDebitCards';
import { ManageExpiredDebitCardDetail } from '@/feature/account-details/screens/ManageExpiredDebitCardDetail';
import { PasswordEdit } from '@/feature/account-details/screens/PasswordEdit';
import { PhoneNumberEdit } from '@/feature/account-details/screens/PhoneNumberEdit';
import { PhoneNumberEditInitialVerification } from '@/feature/account-details/screens/PhoneNumberEditInitialVerification';
import { PhoneNumberEditUpdatedVerification } from '@/feature/account-details/screens/PhoneNumberEditUpdatedVerification';
import { useModals } from '@/feature/alerts/hooks/use-modals';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsWrapper } from '@/feature/analytics/components/AnalyticsWrapper';
import { ScreenName, shouldTrackScreenView } from '@/feature/analytics/utils/analyticsUtils';
import { GuardBiometricsScreen } from '@/feature/authentication/screens/GuardBiometrics';
import { LoginScreen } from '@/feature/authentication/screens/Login';
import { RegisterScreen } from '@/feature/authentication/screens/Register';
import { BetShareScreen } from '@/feature/bet-share-sbk/screens/BetShareScreen';
import { BetScreen as SbkBetScreen } from '@/feature/bets-sbk/screens/BetScreen';
import { EditQuickAmounts } from '@/feature/betslip-pickem/screens/EditQuickAmounts';
import { EntryInputAmount } from '@/feature/betslip-pickem/screens/EntryInputAmount';
import { FantasyPickSlip } from '@/feature/betslip-pickem/screens/FantasyPickSlip';
import { BetSlipScreen } from '@/feature/betslip-sbk/screens/BetSlip';
import { CancelEntryScreen } from '@/feature/cancel-entry/screens/CancelEntry';
import { AddDebitCardWebView } from '@/feature/deposit/screens/AddDebitCardWebView';
import { DepositScreen } from '@/feature/deposit/screens/Deposit';
import { DepositExpiryUpdate } from '@/feature/deposit/screens/DepositExpiryUpdate';
import { DepositMethodWebView as DepositMethodWebViewModal } from '@/feature/deposit/screens/DepositMethodWebView';
import { DepositSalvage } from '@/feature/deposit/screens/DepositSalvage';
import { DepositStatus as DepositStatusModal } from '@/feature/deposit/screens/DepositStatus';
import { FailedDeposit as FailedDepositModal } from '@/feature/deposit/screens/FailedDeposit';
import { MazoomaDepositWebView as MazoomaDepositWebViewModal } from '@/feature/deposit/screens/MazoomaDepositWebView';
import { PayByBankDepositWebView as PayByBankDepositWebViewModal } from '@/feature/deposit/screens/PayByBankDepositWebView';
import { SuccessfulDeposit as SuccessfulDepositModal } from '@/feature/deposit/screens/SuccessfulDeposit';
import { VenmoDepositScreen } from '@/feature/deposit/screens/VenmoDepositScreen';
import { VerifyingDebitDeposit } from '@/feature/deposit/screens/VerifyingDebitDeposit';
import { useLinkingStore } from '@/feature/entry-share/hooks/use-linking';
import { EntryShare } from '@/feature/entry-share/screens/EntryShare';
import { AcknowledgementsStep } from '@/feature/kyc/screens/Acknowledgements/AcknowledgementsStep';
import { DobReview } from '@/feature/kyc/screens/Dob/DobReview';
import { DobStep } from '@/feature/kyc/screens/Dob/DobStep';
import { FirstLastNameReview } from '@/feature/kyc/screens/FirstLastName/FirstLastNameReview';
import { FirstLastNameStep } from '@/feature/kyc/screens/FirstLastName/FirstLastNameStep';
import { HomeAddressReview } from '@/feature/kyc/screens/HomeAddress/HomeAddressReview';
import { HomeAddressStep } from '@/feature/kyc/screens/HomeAddress/HomeAddressStep';
import { IdpvWebView } from '@/feature/kyc/screens/Idpv/IdpvWebView';
import { ScanId } from '@/feature/kyc/screens/Idpv/ScanId';
import { ReviewPersonalInfo } from '@/feature/kyc/screens/ReviewPersonalInfo';
import { SsnRetry } from '@/feature/kyc/screens/Ssn/SsnRetry';
import { SsnStep } from '@/feature/kyc/screens/Ssn/SsnStep';
import { VerificationFailed } from '@/feature/kyc/screens/Verification/VerificationFailed';
import { VerificationSuccess } from '@/feature/kyc/screens/Verification/VerificationSuccess';
import { VerifyingIdentity } from '@/feature/kyc/screens/Verification/VerifyingIdentity';
import { VerifyingIdpv } from '@/feature/kyc/screens/Verification/VerifyingIdpv';
import { MaintenanceScreen } from '@/feature/maintenance/screens/MaintenanceScreen';
import { ProductTransitionScreen } from '@/feature/product-transition/ProductTransitionScreen';
import { PromoHubStack } from '@/feature/promohub/PromohubStack';
import { PushPrimerScreen } from '@/feature/push-primer/screens/PushPrimer';
import { DepositLimitAdd as DepositLimitAddModal } from '@/feature/responsible-gaming/screens/DepositLimitAdd';
import { DepositLimitEdit as DepositLimitEditModal } from '@/feature/responsible-gaming/screens/DepositLimitEdit';
import { DepositLimitsScreen } from '@/feature/responsible-gaming/screens/DepositLimits';
import { EntryLimitAdd as EntryLimitAddModal } from '@/feature/responsible-gaming/screens/EntryLimitAdd';
import { EntryLimitEdit as EntryLimitEditModal } from '@/feature/responsible-gaming/screens/EntryLimitEdit';
import { EntryLimitsScreen } from '@/feature/responsible-gaming/screens/EntryLimits';
import { MaxSingleEntryLimitAdd as MaxSingleEntryLimitAddModal } from '@/feature/responsible-gaming/screens/MaxSingleEntryLimitAdd';
import { MaxSingleEntryLimitEdit as MaxSingleEntryLimitEditModal } from '@/feature/responsible-gaming/screens/MaxSingleEntryLimitEdit';
import { MaxSingleEntryLimitsScreen } from '@/feature/responsible-gaming/screens/MaxSingleEntryLimits';
import { SelfExclusionAdd as SelfExclusionScreen } from '@/feature/responsible-gaming/screens/SelfExclusionAdd';
import { StateLimitsScreen } from '@/feature/responsible-gaming/screens/StateLimits';
import { TimeoutScreen } from '@/feature/responsible-gaming/screens/Timeout';
import { TimeoutAdd as TimeoutAddModal } from '@/feature/responsible-gaming/screens/TimeoutAdd';
import { SearchScreen } from '@/feature/search/screens/SearchScreen';
import { PhoneVerificationScreen } from '@/feature/verify-phone-number/screens/PhoneVerification';
import { WithdrawFormView } from '@/feature/withdraw/screens/WithdrawForm';
import { WithdrawalExpiryUpdate } from '@/feature/withdraw/screens/WithdrawalExpiryUpdate';
import { useAvailableProducts } from '@/hooks/use-available-products';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { ComposeProviders } from '@/providers/ComposeProviders';
import { IneligibleCountryScreen } from '@/screens/IneligibleCountryScreen';
import { SuccessModal } from '@/screens/Modals/SuccessModal';
import { designSystem } from '@/styles/styles';
import { save } from '@/utils/async-storage';
import { isWeb } from '@/utils/constants-platform-specific';
import { isPicksSpecificUrl, isSbkSpecificUrl } from '@/utils/linking';
import { logger } from '@/utils/logging';
import { subscribe } from 'valtio';

import { SubmitEntryScreen } from '../feature/betslip-pickem/screens/SubmitEntry';
import { DeveloperScreen } from '../feature/devsettings/screens/DeveloperScreen';
import { EntryScreen } from '../feature/entries-pickem/screens/EntryScreen';
import { ChooseWithdrawalMethod } from '../feature/withdraw/screens/ChooseWithdrawalMethod';
import { FailedMultipleWithdrawal } from '../feature/withdraw/screens/FailedMultipleWithdrawal';
import { FailedWithdrawal } from '../feature/withdraw/screens/FailedWithdrawal';
import { SuccessfulWithdrawal } from '../feature/withdraw/screens/SuccessfulWithdrawal';
import { useResumeEffect } from '../hooks/use-resume';
import { user } from '../hooks/use-user';
import { AllowLocationScreen } from '../screens/AllowLocation/AllowLocation';
import { LogoutScreen } from '../screens/Auth/LogoutScreen';
import { LaunchScreen } from '../screens/Launch/Launch';
import { ErrorModal } from '../screens/Modals/ErrorModal';
import { SplashScreen } from '../screens/Splash/Splash';
import { ModalWebViewScreen } from '../screens/WebView/ModalWebView';
import { navAnim } from './animations';
import { PickemTabsNavigator } from './pickem/tabs';
import { SbkTabsNavigator } from './sbk/tabs';
import { RootStackParamList } from './types';

const Stack = createStackNavigator<RootStackParamList>();

export type ScreenNames = keyof RootStackParamList;
//Routes where if session expires, we should not go back to the Launch screen, as those routes should be accessed
//even if the user is not logged in
const PUBLIC_ROUTES: ScreenNames[] = [
    'Launch',
    'Login',
    'Register',
    'AllowLocation',
    'Splash',
    'IneligibleStateModal',
    'IneligibleLocationWeb',
];

const screenOptions = {
    headerShown: false,
    tabBarBackground: () => <View />,
    freezeOnBlur: true,
};

// Routes that should not be accessible via direct link since they can broke navigation and app state
const BLACKLISTED_ROUTES = ['picks/submit-entry', 'picks/cancel-entry'];

const AuthGuard: React.FC<PropsWithChildren & { onAuthExpired: () => void }> = ({ children, onAuthExpired }) => {
    // dismissing the modals / sheets here because we're inside the modals provider
    const { dismissAll } = useModals();

    useEffect(() => {
        return subscribe(user, () => {
            const expired = user.sessionHasExpired();
            if (expired) {
                onAuthExpired();
                dismissAll();
            }
        });
    }, [dismissAll, onAuthExpired]);

    const reload = useCallback(() => {
        const expired = user.sessionHasExpired();
        if (expired) {
            onAuthExpired();
            dismissAll();
        }
    }, [dismissAll, onAuthExpired]);

    useResumeEffect(reload);

    return <>{children}</>;
};

export const ROOT_NAVIGATOR_ID = 'root_navigator';

export const NavigationRouter = ({ providers }: { providers: React.FC<{ children: ReactNode | ReactNode[] }>[] }) => {
    const routeNameRef = useRef<string>();
    const navigationRef = useNavigationContainerRef<RootStackParamList>();
    const handleReady = useCallback(() => {
        routeNameRef.current = navigationRef?.current?.getCurrentRoute()?.name;
        enableDataDogNavTracking(navigationRef);
    }, [navigationRef]);
    const { isProductAvailable, isProductActive } = useAvailableProducts();

    const handleStateChange = useCallback(() => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.current?.getCurrentRoute()?.name;

        // if DEV show navigation screens
        if (__DEV__) {
            // E.g.: INFO  [navigation] EntriesScreen -> EntryScreen
            console.info('[navigation]', `${previousRouteName} -> ${currentRouteName}`);
        }

        if (previousRouteName !== currentRouteName) {
            if (currentRouteName && shouldTrackScreenView(currentRouteName as ScreenName)) {
                BetrAnalytics.trackScreenView(currentRouteName);
            }
        }

        routeNameRef.current = currentRouteName;
    }, [navigationRef]);

    const sbkActive = isProductActive(Product.Sportsbook);

    const linking: LinkingOptions<RootStackParamList> = {
        prefixes: ['betrpickem://', 'https://link.betr.app'],
        getStateFromPath: (path, opts) => {
            // we want for some routes (eg submit-entry) to be accessible only via navigation
            // and not via web urls so we will redirect to the splash screen
            // if the user tries to access them directly via web url
            if (BLACKLISTED_ROUTES.some(route => path.includes(route))) {
                return {
                    routes: [{ name: 'Splash' }],
                };
            }

            if (isWeb) {
                // if UTM parameters are present, save them to LocalStorage
                if (path.includes('utm')) {
                    const utmParameters: Record<string, string> = {};
                    const parameters = new URLSearchParams(path);
                    parameters.forEach((value, key) => {
                        if (key.includes('utm')) {
                            utmParameters[key] = value;
                        }
                    });
                    if (Object.keys(utmParameters).length > 0) {
                        save('utm_parameters', utmParameters);
                    }
                }

                // if deep_link_sub1 is present, then we have a promo code
                if (path.includes('deep_link_sub1')) {
                    logger.info('New onelink link', path);
                    const params = new URLSearchParams(path);
                    // deep_link_sub1 is used for filling the promo code
                    const promoCode = params.get('deep_link_sub1');
                    if (promoCode) {
                        save('promo_code', promoCode);
                    }
                }
            }

            // prevent users from bypassing the auth flow via deeplinks
            if (user.sessionHasExpired()) {
                const isAuthPath = path.includes('auth') || path.includes('login');
                const isLoginRoute = routeNameRef.current === 'Login';

                if (isWeb) {
                    if (path.includes('entry/')) {
                        // save share entry links to handle once the user logs in
                        save('webPendingLink', path);
                    }

                    if (!isAuthPath) {
                        return { routes: [{ name: 'Splash' }] };
                    }

                    return getStateFromPath(path, opts);
                }

                useLinkingStore.getState().setPendingLink(path);

                if (isLoginRoute) {
                    return;
                }
                return { routes: [{ name: 'Splash' }] };
            }

            const jurisdiction = useJurisdictionStore.getState().jurisdiction;
            const availableProducts = useJurisdictionStore.getState().jurisdictionSettings?.products ?? [];
            const picksActive = isProductActive(Product.Pickem);
            const picksAvailable = isProductAvailable(Product.Pickem);
            const sbkAvailable = isProductAvailable(Product.Sportsbook);
            const setProduct = useJurisdictionStore.getState().actions.setProduct;

            // since shared entry links don't include 'picks/' we include it in the check here
            if (isPicksSpecificUrl(path)) {
                // for picks/ routes, ensure that the jurisdiction is set and that picks is active, otherwise go to Splash
                if (jurisdiction && (picksActive || picksAvailable)) {
                    setProduct(Product.Pickem);
                    if (path.startsWith('entry/')) {
                        // app might be opened on a different product so store the path and go to picks home
                        useLinkingStore.getState().setPendingLink(path);
                        return {
                            routes: [
                                {
                                    name: 'PickemHome',
                                },
                            ],
                        };
                    } else {
                        return getStateFromPath(path, opts);
                    }
                } else {
                    return {
                        routes: [{ name: 'Splash' }],
                    };
                }
            } else if (isSbkSpecificUrl(path)) {
                // for sbk/ routes, ensure that the jurisdiction is set and that sbk is active, otherwise go to Splash
                if (jurisdiction && (sbkActive || sbkAvailable)) {
                    setProduct(Product.Sportsbook);
                    return getStateFromPath(path, opts);
                } else {
                    return {
                        routes: [{ name: 'Splash' }],
                    };
                }
            } else {
                // if the path is neither picks nor sbk, check if at least one product is available
                if (availableProducts.length > 0) {
                    if (picksActive || sbkActive) {
                        // if either picks or sbk are active, return the current state
                        return getStateFromPath(path, opts);
                    } else {
                        // if none of the products are active, set the product to the first available one
                        return {
                            routes: [{ name: 'Splash' }],
                        };
                    }
                } else {
                    // if there are no products, go to splash
                    return {
                        routes: [{ name: 'Splash' }],
                    };
                }
            }
        },
        async getInitialURL() {
            // return undefined so we don't handle the URL yet (the navigator might not be ready)
            // these links will be handled in the useLinking hook
            return undefined;
        },
        /**
         * Navigation can break on the web if we don't specify all screens in a stack
         * We need to explicitly define each screen that the user can navigate to instead of relying on default screen names
         * Failing to do so can cause navigation to reset to the initial screen
         *
         * While this is sometimes desirable, since we can easily block the user to directly access some screens
         * (such as preventing direct access to verification screens),
         * there are specific cases (like the lobby or account stack) where we need to set an initial route
         * Otherwise, navigation can break when moving forward or backward in the browser,
         * refreshing the screen, or directly accessing certain screens
         *
         * This also affects the app because it might not load correctly all the necessary screens for the app to work properly
         * Some params may not be defined (since are not serialized correctly), or some data is missing,
         * data that normally loads on the screen before the one we are trying to directly access
         *
         * Currently, navigation linking types are broken on nested stacks, so we will use ts-ignore for now
         * (https://github.com/react-navigation/react-navigation/issues/11332 & https://github.com/react-navigation/react-navigation/issues/10876)
         */
        config: {
            initialRouteName: 'Splash',
            screens: {
                Launch: 'auth',
                Login: 'login',
                DepositScreen: 'deposit',
                DepositStatusModal: 'deposit-status',
                KycVerifyingIdpv: 'kyc-verifying-idpv',
                KycScanId: 'kyc-scan-id',
                ManageExpiredDebitCardDetail: 'update-expired-card',
                AccountHome: {
                    // see comment above ignoring ts
                    // @ts-ignore
                    initialRouteName: 'Account',
                    path: 'account',
                    screens: {
                        Account: 'account',
                        GamingLimits: 'gaming-limits',
                        DepositLimits: 'deposit-limits',
                        EntryLimits: 'entry-limits',
                        MaxSingleEntryLimits: 'max-single-entry-limits',
                        StateLimits: 'state-limits',
                        RealityCheck: 'reality-check',
                        Timeout: 'timeout',
                        SelfExclusionAdd: 'self-exclusion',
                        TransactionHistory: 'transaction-history',
                        AccountDetails: 'account-details',
                        YearEndStatement: 'year-end-statement',
                        StateSelector: 'state-selector',
                        TransactionDetails: 'transaction-details',
                        Preferences: 'preferences',
                        ResponsibleGaming: 'responsible-gaming',
                        ManageDebitCards: 'manage-debit-cards',
                    },
                },
                Promohub: {
                    screens: {
                        Promohub: {
                            path: 'promos/:promoId?',
                        },
                    },
                },
                PickemHome: {
                    path: 'picks',
                    screens: {
                        Lobby: {
                            // see comment above ignoring ts
                            // @ts-ignore
                            initialRouteName: 'LobbyScreen',
                            screens: {
                                LobbyScreen: 'home',
                                PickemSportScreen: {
                                    path: 'league/:league',
                                    screens: {
                                        'PickemSport-GAMES': {
                                            path: 'games',
                                        },
                                        'PickemSport-PLAYERS': {
                                            path: 'players/:pickId?',
                                        },
                                        'PickemSport-PROJECTIONS': {
                                            path: 'projections',
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                FantasyPickSlip: 'picks/fantasy-pick-slip',
                EntryScreen: 'picks/entry',
                EntryInputAmount: 'picks/entry-amount',
                EditQuickAmounts: 'picks/edit-quick-amounts',
                SubmitEntry: 'picks/submit-entry',
                CancelEntry: 'picks/cancel-entry',
                EntryShare: 'picks/entry-share',
                SBKHome: {
                    path: 'sbk',
                    screens: {
                        SBKLobby: {
                            screens: {
                                BettingLobbyScreen: 'home',
                            },
                        },
                    },
                },
                ModalWebView: {
                    path: 'webview/:uri',
                    parse: {
                        uri: (uri: string) => `https://${decodeURIComponent(uri)}`,
                    },
                    stringify: {
                        uri: (uri: string) => encodeURIComponent(uri.replace(/^https:\/\//, '')),
                    },
                },
            },
        },
        filter(url) {
            // Prevent deep linking to auth screens if user is logged in
            if (url.includes('auth') || url.includes('login')) {
                return user.sessionHasExpired();
            }
            return true;
        },
    };

    const gestureDisabled = { gestureEnabled: false };

    return (
        <NavigationContainer
            linking={linking}
            ref={navigationRef}
            onReady={handleReady}
            documentTitle={{ enabled: false }}
            onStateChange={handleStateChange}
            theme={{
                ...DefaultTheme,
                colors: { ...DefaultTheme.colors, background: designSystem.colors.gray8 },
            }}
        >
            <ComposeProviders providers={providers}>
                <AnalyticsWrapper navigationRef={navigationRef}>
                    <AuthGuard
                        onAuthExpired={() => {
                            if (!routeNameRef.current) {
                                logger.debug(
                                    '[AUTH GUARD]',
                                    'got auth expired but routes not setup yet. Can probably ignore.'
                                );
                                return;
                            }
                            logger.debug('[AUTH GUARD]', 'expired');
                            if (PUBLIC_ROUTES.includes(routeNameRef.current as ScreenNames)) {
                                logger.debug(
                                    '[AUTH GUARD]',
                                    'expired, but user in a public route.',
                                    routeNameRef.current
                                );
                            } else {
                                logger.debug(
                                    '[AUTH GUARD]',
                                    `expired, user in a protected route(${routeNameRef.current}). Reset to Login.`,
                                    routeNameRef.current
                                );
                                const email = user?.profile?.email;
                                navigationRef.reset({
                                    routes: [{ name: 'Login', params: { initialEmail: email } }],
                                });
                            }
                        }}
                    >
                        <Stack.Navigator
                            screenOptions={screenOptions}
                            initialRouteName={'Splash'}
                            id={ROOT_NAVIGATOR_ID}
                        >
                            <Stack.Screen
                                name="Splash"
                                component={SplashScreen}
                                options={{ animationEnabled: false }}
                            />
                            <Stack.Screen
                                name="Launch"
                                component={LaunchScreen}
                                options={{ animationEnabled: false }}
                            />
                            <Stack.Screen
                                name="IneligibleLocationWeb"
                                component={IneligibleCountryScreen}
                                options={{ animationEnabled: false }}
                            />
                            <Stack.Screen
                                name="AllowLocation"
                                component={AllowLocationScreen}
                                // enables scroll on web
                                options={{ cardStyle: { flex: 1 } }}
                            />
                            <Stack.Screen name="Logout" component={LogoutScreen} />
                            {/** Root screens */}
                            <Stack.Screen
                                name="ProductTransitionScreen"
                                component={ProductTransitionScreen}
                                /**
                                 * We need to disable the animation for the ProductTransitionScreen
                                 * We animate the transition to and from this screen manually inside the screen's component
                                 * react-navigation has an option for disabling the animation, but it doesn't work as expected
                                 */
                                options={() => ({
                                    // we need every container to have the background `transparent`
                                    cardStyle: { backgroundColor: 'transparent' },
                                    cardStyleInterpolator: () => {
                                        return {
                                            containerStyle: {
                                                // full opacity here to avoid flickering
                                                opacity: 1,
                                                backgroundColor: 'transparent',
                                            },
                                            cardStyle: {
                                                backgroundColor: 'transparent',
                                            },
                                        };
                                    },
                                    // disable the animation config for the screen
                                    transitionConfig: () => ({
                                        transitionSpec: {
                                            duration: 0,
                                            timing: Animated.timing,
                                            easing: Easing.step0,
                                        },
                                    }),
                                    // show it as a transparent modal so that the stack navigator doesn't have a background
                                    presentation: 'transparentModal',
                                    gestureEnabled: false,
                                })}
                            />
                            <Stack.Screen
                                name="SearchScreen"
                                component={SearchScreen}
                                options={() => ({
                                    fullScreenGestureEnabled: true,
                                    // we need every container to have the background `transparent`
                                    cardStyle: { backgroundColor: 'transparent', flex: 1 },
                                    cardStyleInterpolator: () => {
                                        return {
                                            containerStyle: {
                                                // full opacity here to avoid flickering
                                                opacity: 1,
                                                backgroundColor: 'transparent',
                                            },
                                            cardStyle: {
                                                backgroundColor: 'transparent',
                                            },
                                        };
                                    },
                                    // disable the animation config for the screen
                                    transitionConfig: () => ({
                                        transitionSpec: {
                                            duration: 0,
                                            timing: Animated.timing,
                                            easing: Easing.step0,
                                        },
                                    }),
                                    // show it as a transparent modal so that the stack navigator doesn't have a background
                                    presentation: 'transparentModal',
                                    gestureEnabled: false,
                                    // animation is disabled only on android - on ios it flickers if it's disabled
                                    animationEnabled: Platform.OS !== 'android',
                                })}
                            />
                            <Stack.Screen
                                name="PickemHome"
                                component={PickemTabsNavigator}
                                options={{ animationEnabled: false }}
                            />
                            <Stack.Screen
                                name="SBKHome"
                                component={SbkTabsNavigator}
                                options={{ animationEnabled: false }}
                            />
                            <Stack.Screen name="AccountHome" component={AccountStack} />
                            <Stack.Screen name="Promohub" component={PromoHubStack} />
                            {/** Pickem entries & slip */}
                            <Stack.Screen name="EntryScreen" component={EntryScreen} options={navAnim.slideUp} />
                            <Stack.Screen
                                name="FantasyPickSlip"
                                component={FantasyPickSlip}
                                options={{ ...navAnim.slideUp, cardStyle: { flex: 1 } }}
                            />
                            <Stack.Screen
                                name="EntryInputAmount"
                                component={EntryInputAmount}
                                options={navAnim.slideUp}
                            />
                            <Stack.Screen name="EditQuickAmounts" component={EditQuickAmounts} />
                            <Stack.Screen
                                name="SubmitEntry"
                                component={SubmitEntryScreen}
                                options={{
                                    ...navAnim.slideUp,
                                    gestureEnabled: false, //we don't want to allow the user to swipe dismiss while entry is submitting
                                    // disabled on Android because of the default cross-fade animation causing some issues (SBK-212)
                                    animationEnabled: Platform.OS === 'ios',
                                }}
                            />
                            <Stack.Screen
                                name="CancelEntry"
                                component={CancelEntryScreen}
                                options={{
                                    ...navAnim.slideUp,
                                    gestureEnabled: false, //we don't want to allow the user to swipe dismiss while entry is submitting
                                    // disabled on Android because of the default cross-fade animation causing some issues (SBK-212)
                                    animationEnabled: Platform.OS === 'ios',
                                }}
                            />
                            <Stack.Screen name="EntryShare" component={EntryShare} options={navAnim.slideUp} />
                            <Stack.Screen name="PushPrimer" component={PushPrimerScreen} options={navAnim.slideUp} />
                            {/** Sbk Bet slip */}
                            <Stack.Screen name="BetSlipModal" component={BetSlipScreen} options={navAnim.slideUp} />
                            {/* Sbk My bets */}
                            <Stack.Screen name="SbkBetScreen" component={SbkBetScreen} options={navAnim.slideUp} />
                            {/** SBK Bet Share */}
                            <Stack.Screen name="BetShareScreen" component={BetShareScreen} options={navAnim.slideUp} />
                            {/**Generic */}
                            <Stack.Screen
                                name="ErrorModal"
                                component={ErrorModal}
                                options={({ route }) => ({
                                    gestureEnabled: route.params?.gestureEnabled,
                                    ...(route.params?.closeIconMode !== 'back' ? navAnim.slideUp : {}),
                                })}
                            />
                            <Stack.Screen
                                name="IneligibleStateModal"
                                component={ErrorModal}
                                options={navAnim.slideUp}
                            />
                            <Stack.Screen
                                name="ModalWebView"
                                component={ModalWebViewScreen}
                                options={navAnim.slideUp}
                            />
                            {/**Deposit & Withdraw */}
                            {/* cardStyle: { flex: 1 } was added to fix the scrolling issue with the Payment Method list on the WEB Platform */}
                            <Stack.Screen
                                name="DepositScreen"
                                component={DepositScreen}
                                options={{ ...navAnim.slideUp, cardStyle: { flex: 1 } }}
                            />
                            <Stack.Screen
                                name="SuccessfulDepositModal"
                                component={SuccessfulDepositModal}
                                options={{ ...gestureDisabled, ...navAnim.slideUp }}
                            />
                            <Stack.Screen
                                name="FailedDepositModal"
                                component={FailedDepositModal}
                                options={{ ...gestureDisabled, ...navAnim.slideUp }}
                            />
                            <Stack.Screen
                                name="AddDebitCardWebView"
                                component={AddDebitCardWebView}
                                options={navAnim.slideUp}
                            />
                            <Stack.Screen
                                name="DepositMethodWebViewModal"
                                component={DepositMethodWebViewModal}
                                options={{ ...gestureDisabled }}
                            />
                            <Stack.Screen name="MazoomaDepositWebViewModal" component={MazoomaDepositWebViewModal} />
                            <Stack.Screen
                                name="PayByBankDepositWebViewModal"
                                component={PayByBankDepositWebViewModal}
                            />
                            <Stack.Screen name="VenmoDepositScreen" component={VenmoDepositScreen} />
                            <Stack.Screen name="DepositStatusModal" component={DepositStatusModal} />
                            <Stack.Screen name="VerifyingDebitDeposit" component={VerifyingDebitDeposit} />
                            <Stack.Screen
                                name="DepositSalvage"
                                component={DepositSalvage}
                                options={{ ...navAnim.slideUp }}
                            />
                            <Stack.Screen
                                name="DepositExpiryUpdate"
                                component={DepositExpiryUpdate}
                                options={{ ...gestureDisabled }}
                            />
                            <Stack.Screen
                                name="PhoneVerification"
                                component={PhoneVerificationScreen}
                                options={{ ...gestureDisabled }}
                            />
                            <Stack.Screen name="PasswordEdit" component={PasswordEdit} />
                            <Stack.Screen
                                name="SuccessModal"
                                component={SuccessModal}
                                options={{ ...gestureDisabled, ...navAnim.slideUp }}
                            />
                            <Stack.Screen name="EmailUpdate" component={EmailUpdate} options={{ ...gestureDisabled }} />
                            <Stack.Screen
                                name="EmailUpdateConfirm"
                                component={EmailUpdateConfirm}
                                options={{ ...gestureDisabled }}
                            />
                            <Stack.Screen
                                name="PhoneNumberEditInitialVerification"
                                component={PhoneNumberEditInitialVerification}
                                options={{ ...gestureDisabled }}
                            />
                            <Stack.Screen
                                name="PhoneNumberEditUpdatedVerification"
                                component={PhoneNumberEditUpdatedVerification}
                                options={{ ...gestureDisabled }}
                            />
                            <Stack.Screen
                                name="PhoneNumberEdit"
                                component={PhoneNumberEdit}
                                options={{ ...gestureDisabled }}
                            />
                            <Stack.Screen
                                name="ManageDebitCards"
                                component={ManageDebitCards}
                                options={{ ...gestureDisabled, cardStyle: { flex: 1 } }}
                            />
                            <Stack.Screen
                                name="ManageExpiredDebitCardDetail"
                                component={ManageExpiredDebitCardDetail}
                                options={{ ...gestureDisabled }}
                            />
                            <Stack.Screen
                                name="ManageDebitCardExpiryUpdate"
                                component={ManageDebitCardExpiryUpdate}
                                options={{ ...gestureDisabled }}
                            />
                            <Stack.Screen
                                name="ChooseWithdrawalMethod"
                                component={ChooseWithdrawalMethod}
                                options={{ ...navAnim.slideUp, cardStyle: { flex: 1 } }}
                            />
                            <Stack.Screen name="WithdrawForm" component={WithdrawFormView} />
                            <Stack.Screen
                                name="FailedWithdrawal"
                                component={FailedWithdrawal}
                                options={{ ...gestureDisabled, ...navAnim.slideUp }}
                            />
                            <Stack.Screen
                                name="FailedMultipleWithdrawal"
                                component={FailedMultipleWithdrawal}
                                options={{ ...gestureDisabled, ...navAnim.slideUp }}
                            />
                            <Stack.Screen
                                name="SuccessfulWithdrawal"
                                component={SuccessfulWithdrawal}
                                options={{ ...gestureDisabled, ...navAnim.slideUp }}
                            />
                            <Stack.Screen
                                name="WithdrawalExpiryUpdate"
                                component={WithdrawalExpiryUpdate}
                                options={{ ...gestureDisabled }}
                            />
                            <Stack.Screen name="DepositLimits" component={DepositLimitsScreen} />
                            <Stack.Screen name="ResponsibleGaming" component={GamingLimitsScreen} />
                            <Stack.Screen name="StateLimits" component={StateLimitsScreen} />
                            <Stack.Screen name="EntryLimits" component={EntryLimitsScreen} />
                            <Stack.Screen name="MaxSingleEntryLimits" component={MaxSingleEntryLimitsScreen} />
                            <Stack.Screen
                                name="DepositLimitAdd"
                                component={DepositLimitAddModal}
                                options={navAnim.slideUp}
                            />
                            <Stack.Screen
                                name="DepositLimitEdit"
                                component={DepositLimitEditModal}
                                options={navAnim.slideUp}
                            />
                            <Stack.Screen
                                name="MaxSingleEntryLimitEdit"
                                component={MaxSingleEntryLimitEditModal}
                                options={navAnim.slideUp}
                            />
                            <Stack.Screen
                                name="EntryLimitAdd"
                                component={EntryLimitAddModal}
                                options={navAnim.slideUp}
                            />
                            <Stack.Screen
                                name="EntryLimitEdit"
                                component={EntryLimitEditModal}
                                options={navAnim.slideUp}
                            />
                            <Stack.Screen
                                name="MaxSingleEntryLimitAdd"
                                component={MaxSingleEntryLimitAddModal}
                                options={navAnim.slideUp}
                            />
                            <Stack.Screen name="TimeoutAdd" component={TimeoutAddModal} options={navAnim.slideUp} />
                            <Stack.Screen name="Timeout" component={TimeoutScreen} />
                            <Stack.Screen name="SelfExclusionAdd" component={SelfExclusionScreen} />
                            <Stack.Screen name="Login" component={LoginScreen} options={navAnim.slideUp} />
                            <Stack.Screen
                                name="GuardBiometricsScreen"
                                component={GuardBiometricsScreen}
                                options={navAnim.slideUp}
                            />
                            <Stack.Screen name="Register" component={RegisterScreen} options={navAnim.slideUp} />
                            {/** KYC Flow **/}
                            <Stack.Screen name="KycSsn" component={SsnStep} />
                            <Stack.Screen name="KycFirstLastName" component={FirstLastNameStep} />
                            <Stack.Screen name="KycDob" component={DobStep} />
                            <Stack.Screen name="KycHomeAddress" component={HomeAddressStep} />
                            <Stack.Screen name="KycAcknowledgements" component={AcknowledgementsStep} />
                            {/** KYC Retry Flow **/}
                            <Stack.Screen name="KycSsnRetry" component={SsnRetry} />
                            <Stack.Screen name="KycReviewPersonalInfo" component={ReviewPersonalInfo} />
                            <Stack.Screen name="KycAcknowledgementsRetry" component={AcknowledgementsStep} />
                            {/** KYC Review Fields **/}
                            <Stack.Screen
                                name="KycFirstLastNameReview"
                                component={FirstLastNameReview}
                                options={navAnim.slideUp}
                            />
                            <Stack.Screen name="KycDobReview" component={DobReview} options={navAnim.slideUp} />
                            <Stack.Screen
                                name="KycHomeAddressReview"
                                component={HomeAddressReview}
                                options={navAnim.slideUp}
                            />
                            {/* Kyc Retry Flow - Scan ID */}
                            <Stack.Screen name="KycScanId" component={ScanId} />
                            <Stack.Screen name="KycIdpvWebView" component={IdpvWebView} options={navAnim.slideUp} />
                            {/** KYC **/}
                            <Stack.Screen
                                name="KycVerificationFailed"
                                component={VerificationFailed}
                                options={{ ...gestureDisabled, ...navAnim.slideUp }}
                            />
                            <Stack.Screen
                                name="KycVerifyingIdentity"
                                component={VerifyingIdentity}
                                options={{ ...gestureDisabled, ...navAnim.slideUp }}
                            />
                            <Stack.Screen
                                name="KycVerifyingIdpv"
                                component={VerifyingIdpv}
                                options={{ ...gestureDisabled, ...navAnim.slideUp }}
                            />
                            <Stack.Screen
                                name="KycVerificationSuccess"
                                component={VerificationSuccess}
                                options={
                                    sbkActive
                                        ? { ...gestureDisabled, ...navAnim.slideUp }
                                        : { ...gestureDisabled, ...navAnim.noAnimate }
                                }
                            />
                            <Stack.Screen name="DeveloperScreen" component={DeveloperScreen} />
                            <Stack.Screen
                                name="MaintenanceScreen"
                                component={MaintenanceScreen}
                                options={{ ...gestureDisabled }}
                            />
                        </Stack.Navigator>
                    </AuthGuard>
                </AnalyticsWrapper>
            </ComposeProviders>
        </NavigationContainer>
    );
};
