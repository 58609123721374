import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, RefreshControl, SectionList, SectionListProps, StyleSheet, View } from 'react-native';
import { Platform } from 'react-native';
import Animated from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useEventsInfoQuery } from '@/api/events/query.generated';
import { EventInfo, EventInfoWithoutPlayers } from '@/api/events/types/types';
import InfoIcon from '@/assets/icons/info';
import { LineSeparator } from '@/components/LineSeparator';
import { Loading } from '@/components/Loading';
import { SizedBox } from '@/components/SizedBox';
import { useStickyTabList, useStickyTabs } from '@/components/StickyTabsProvider';
import { Text } from '@/components/TextComponent';
import { TAB_HEIGHT } from '@/components/TopTabBar';
import { Box, Row, TouchableBox } from '@/components/lib/components';
import { useGradientImagesEnabled } from '@/components/player-profile/hooks/useGradientImagesEnabled';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { useFantasyPoints } from '@/feature/betslip-pickem/components/FantasyPointsModalProvider';
import {
    playerPropsSelector,
    usePlayerPropsStore,
    useUpdatePlayerStoreWithNewData,
} from '@/feature/betslip-pickem/hooks/use-player-props-store';
import { PlayerWithTeam } from '@/feature/betslip-pickem/types';
import { isPlayerSelected } from '@/feature/betslip-pickem/utils/betslip-utils';
import { eventUtils } from '@/feature/betslip-pickem/utils/event-utils';
import { usePlayerFiltersStore } from '@/feature/lobby/hooks/use-player-filters';
import {
    FilterType,
    Filters,
    getPositionFilters,
    leagueHasPositions,
    nonRegularProjectionTypes,
    specialProjectionType,
} from '@/feature/lobby/utils/filters';
import { Section as ListSection, Section, playersUtils } from '@/feature/lobby/utils/players';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useResponsiveColumnCount } from '@/feature/responsive-design/hooks/use-responsive-column-count';
import { useGetOrderedLeagues } from '@/hooks/use-fantasy-league-configs';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useResumeEffect } from '@/hooks/use-resume';
import { common, designSystem } from '@/styles/styles';
import { MarketStatus, ProjectionType } from '@/types/api.generated';
import { localeCompareCollator } from '@/utils/collator';
import { isWeb } from '@/utils/constants-platform-specific';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';
import { gameUtils } from '@/utils/games';
import { sortEvents } from '@/utils/sortEvents';

import { useOpenDeeplinkPlayer } from '../hooks/use-open-deeplink-player';
import { PickSelectionMethods, usePickSelection } from '../hooks/use-pick-selection';
import { PlayerRow } from './PlayerRow';
import { ProjectionTileRow } from './tile/PlayerTileRow';

// wrap SectionList to make it compatible with reanimated
// unfortunately, the new type doesn't contain the methods of the original SectionList (scrollTo* methods)
const AnimatedSectionList =
    Animated.createAnimatedComponent<SectionListProps<PlayerWithTeam, ListSection>>(SectionList);

const projectionsOrder = [ProjectionType.Special, ProjectionType.Regular, ProjectionType.Boosted];

const FILTERS_HEIGHT = 68;

const configs = {
    default: {
        showsVerticalScrollIndicator: true,
        windowSize: undefined,
        maxToRenderPerBatch: undefined,
        initialNumToRender: undefined,
    },
    native: {
        showsVerticalScrollIndicator: false,
        windowSize: 3,
        maxToRenderPerBatch: 5,
        initialNumToRender: 10,
    },
};
const listProps = Platform.select(configs);

type PlayersList = {
    sortedEvents: EventInfoWithoutPlayers[];
    loading: boolean;
    onRefresh: () => void;
    filterType: FilterType;
    openPlayerWithId?: string;
    translationDistance: number;
    hasFilters: boolean;
    analyticsTag?: string;
};

type PlayerItem = {
    eventInfo: EventInfo;
    player: PlayerWithTeam;
    filter: FilterType;
    projectionType: ProjectionType;
    testID?: string;
    analyticsTag?: string;
} & PickSelectionMethods;
const FANTASY_PTS_PROJECTION = 'Fantasy PTS';

export const EmptyPage = ({ loading, paddingTop }: { loading: boolean; paddingTop?: number }) => {
    const { t } = useTranslation('betslip_pickem');
    if (loading) {
        return (
            <View style={[common.paddingVertical, common.flex, common.justifyCenter, { paddingTop }]}>
                <Loading />
            </View>
        );
    }
    return (
        <Box style={{ paddingTop }}>
            <SizedBox value={20} />
            <Text fontWeight="bold" textAlign={'center'}>
                {t('no_projection_available')}
            </Text>
        </Box>
    );
};

const PlayerItem = memo(
    ({
        eventInfo,
        player,
        filter = Filters.Position,
        projectionType,
        testID,
        analyticsTag,
        openPlayerPickModal,
        removeSelection,
        makeSelection,
    }: PlayerItem) => {
        const playerProjections = usePlayerPropsStore(playerPropsSelector(player.id), defaultZustandCompareFunction);
        const playerProjectionFilter = usePlayerFiltersStore(state => state.playerProjectionFilter);

        /**
         * Filter based on projection key
         */
        const filteredProjections = useMemo(() => {
            return playerProjections.filter(proj => proj.key === playerProjectionFilter);
        }, [playerProjectionFilter, playerProjections]);

        /**
         * Find the projection that user is filtering on
         */
        const projection =
            filteredProjections.find(proj => proj.key === playerProjectionFilter && proj.type === projectionType) ||
            playerProjections[0];

        const playerPickedEntry = isPlayerSelected({ eventId: eventInfo.id, playerId: player && player.id });

        const isThisProjectionPicked =
            playerPickedEntry?.projection.type === projectionType &&
            playerPickedEntry.projection.key === playerProjectionFilter;

        const playerRowProps = isThisProjectionPicked
            ? { mode: 'selection' as const, projection, outcome: playerPickedEntry?.outcome }
            : { mode: 'highlight' as const, projection };

        return (
            <View testID={`listedPlayer-${testID}`}>
                <React.Fragment key={`player-${player.id}-${projection?.key}`}>
                    {filter === Filters.Projection ? (
                        <PlayerRow
                            player={player}
                            event={eventInfo}
                            {...playerRowProps}
                            pressable
                            testID={testID}
                            openPlayerPickModal={openPlayerPickModal}
                            removeSelection={removeSelection}
                            makeSelection={makeSelection}
                            analyticsTag={analyticsTag}
                        />
                    ) : playerPickedEntry ? (
                        <PlayerRow
                            player={player}
                            projection={playerPickedEntry.projection}
                            mode={'selection'}
                            event={eventInfo}
                            outcome={playerPickedEntry.outcome}
                            pressable
                            testID={testID}
                            openPlayerPickModal={openPlayerPickModal}
                            removeSelection={removeSelection}
                            makeSelection={makeSelection}
                            analyticsTag={analyticsTag}
                        />
                    ) : (
                        <PlayerRow
                            player={player}
                            mode={'all'}
                            event={eventInfo}
                            pressable
                            testID={testID}
                            openPlayerPickModal={openPlayerPickModal}
                            removeSelection={removeSelection}
                            makeSelection={makeSelection}
                            analyticsTag={analyticsTag}
                        />
                    )}
                </React.Fragment>
            </View>
        );
    }
);

const ItemSeparator = () => (
    <MaxWidthWrapper>
        <LineSeparator style={[common.separator, styles.marginHorizontal]} />
    </MaxWidthWrapper>
);

/**
 * Component that renders a list of players with projections
 * @param sortedEvents - list of events
 * @param loading - refreshing state
 * @param onRefresh - function to refresh the list
 * @param filterType - player position filter type
 * @param openPlayerWithId - id of the player to open the player card for (used for deep linking)
 */
const PlayersList = memo((props: PlayersList) => {
    const listRef = useRef<SectionList<PlayerWithTeam, ListSection> | null>(null);
    const {
        sortedEvents,
        loading,
        onRefresh,
        filterType,
        openPlayerWithId,
        translationDistance,
        hasFilters,
        analyticsTag,
    } = props;
    const {
        playerPositionFilter,
        playerPositionDescription,
        playerProjectionFilter,
        playerProjectionLabel,
        actions: { filterPositionFunction, filterProjectionsFunction },
    } = usePlayerFiltersStore();

    const enableGradientPlayerProfileImage = useGradientImagesEnabled();
    const isTileEnabled = useJurisdictionStore(
        store => store.jurisdictionSettings?.productConfig?.settings?.projection_tiles_enabled
    );

    const showTile = isTileEnabled && enableGradientPlayerProfileImage;
    const sortedEventsIds = useMemo(() => sortedEvents.map(it => it.id), [sortedEvents]);

    const [{ data, fetching }, execute] = useEventsInfoQuery({
        variables: { ids: sortedEventsIds },
        pause: true,
    });
    const { t } = useTranslation(['betslip_pickem', 'common']);
    const league = sortedEvents[0]?.league;
    const { leagues } = useGetOrderedLeagues();
    const positionFilters = useMemo(() => getPositionFilters(league, leagues), [league, leagues]);
    const renderWithoutPositionFilters = sortedEvents && !leagueHasPositions(league);
    const { showFantasyPointsModal } = useFantasyPoints();
    useUpdatePlayerStoreWithNewData(data?.getEventsByIdsV2);

    const { showInfoSheet } = useAlerts();
    const sortedEventsDetails = useMemo(() => {
        const eventDetails = data?.getEventsByIdsV2 ?? [];
        return eventDetails.sort(sortEvents);
    }, [data?.getEventsByIdsV2]);

    const playerMarkets = usePlayerPropsStore(state => state.playerMarkets);
    const sortedPlayers = useMemo(() => {
        // sorting players
        if (filterType === Filters.Position && (playerPositionFilter !== undefined || renderWithoutPositionFilters)) {
            return sortedEventsDetails.flatMap(event => {
                return gameUtils.filterAndSortPlayers(
                    event,
                    positionFilters,
                    playerPositionFilter !== 'All' ? filterPositionFunction : undefined
                );
            });
        }

        if (filterType === Filters.Projection && playerProjectionFilter !== undefined) {
            const players = gameUtils.filterAndSortPlayersOnProjectionView(
                sortedEventsDetails,
                playerProjectionFilter,
                filterProjectionsFunction
            );
            const hasSelectedMarketActive = (playerId: string) => {
                return (
                    playerMarkets[playerId]?.find(it => it.key === playerProjectionFilter)?.marketStatus ===
                    MarketStatus.Opened
                );
            };
            //Filter out players that have the selected market/projection suspended and sort them by event date
            return players
                .filter(p => hasSelectedMarketActive(p.id))
                .sort((firstPlayer, secondPlayer) => {
                    const firstEventDate = firstPlayer?.eventStartTime;
                    const secondEventDate = secondPlayer?.eventStartTime;

                    if (!firstEventDate || !secondEventDate) {
                        return 0;
                    }
                    return localeCompareCollator.compare(firstEventDate, secondEventDate);
                });
        }
        return [];
    }, [
        filterType,
        playerPositionFilter,
        renderWithoutPositionFilters,
        playerProjectionFilter,
        sortedEventsDetails,
        positionFilters,
        filterPositionFunction,
        filterProjectionsFunction,
        playerMarkets,
    ]);

    const getProjectionLabel = useCallback(
        (projectionType: ProjectionType) => {
            return playersUtils.getProjectionSectionLabel(
                projectionType,
                filterType,
                playerPositionDescription,
                playerProjectionLabel
            );
        },
        [filterType, playerPositionDescription, playerProjectionLabel]
    );

    const groupedPlayersByProjections = useMemo(() => {
        return playersUtils.groupPlayersByProjections(
            sortedPlayers,
            // For devices that don't use the player gradient we default to player row where we still have the boosted section, so we are going to filter based on non regular projection types otherwise we just separate special from the rest.
            showTile ? specialProjectionType : nonRegularProjectionTypes,
            filterType,
            playerProjectionFilter,
            projectionsOrder,
            playerPositionDescription,
            playerProjectionLabel,
            !showTile
        );
    }, [filterType, playerPositionDescription, playerProjectionFilter, playerProjectionLabel, showTile, sortedPlayers]);

    const handleProjectionTypePress = useCallback(
        (projectionType: ProjectionType) => {
            let description = '';
            switch (projectionType) {
                case ProjectionType.Boosted:
                    description = t('boosted_picks_description');
                    break;
                case ProjectionType.Special:
                    description = t('special_picks_description');
                    break;
                default:
                    break;
            }

            if (projectionType !== ProjectionType.Regular) {
                showInfoSheet({
                    title: `${getProjectionLabel(projectionType)}`,
                    description: description,
                    buttonLabel: t('common:dismiss'),
                });
            } else {
                showFantasyPointsModal({ league });
            }
        },
        [getProjectionLabel, league, showFantasyPointsModal, showInfoSheet, t]
    );

    useOpenDeeplinkPlayer(openPlayerWithId, sortedPlayers, sortedEventsDetails);

    const refetch = useCallback(() => {
        execute({ requestPolicy: 'network-only' });
    }, [execute]);

    useResumeEffect(refetch);

    const { scrollableProps, setStickyRef, fakeLoading, fakeRefresh, contentHeight, headerHeight } = useStickyTabList(
        filterType,
        loading,
        onRefresh,
        true
    );

    const { scrollY } = useStickyTabs();

    // This check is used for dedicated events where we have a header image
    // In case we scroll more than the header, when we change tabs we want to be at the beginning of the tab.
    // In case we scroll less than the header, we want to preserve the scroll on the header.
    useEffect(() => {
        listRef.current
            ?.getScrollResponder()
            ?.scrollTo({ y: scrollY.value < headerHeight ? scrollY.value : headerHeight, animated: false });
    }, [headerHeight, playerPositionFilter, playerProjectionFilter, scrollY]);

    const insets = useSafeAreaInsets();

    const fullTabHeight = TAB_HEIGHT + (hasFilters ? FILTERS_HEIGHT : 0);
    const paddingTop = translationDistance + fullTabHeight;
    const paddingBottom = filterType === Filters.Projection ? 26 : 0;
    const { openPlayerPickModal, makeSelection, removeSelection } = usePickSelection();
    const columnsCount = useResponsiveColumnCount([1, 2, 3, 4, 5]);

    const renderItem = useCallback(
        ({ item, index, section }: { item: PlayerWithTeam; index: number; section: ListSection }) => {
            const eventInfo = sortedEventsDetails.find(event => {
                const eventPlayers = eventUtils.getAllPlayers(event);
                return eventPlayers.some(p => p.id === item.id);
            });

            //In case current event is not found we will skip the element
            if (!eventInfo) {
                return null;
            }

            // Devices that don't have gradient enable will default to Player Row.
            if (Filters.Projection === filterType && showTile) {
                return (
                    <ProjectionTileRow
                        index={index}
                        sortedEventsDetails={sortedEventsDetails}
                        section={section}
                        analyticsTag={analyticsTag}
                        sortedPlayers={sortedPlayers}
                        openPlayerPickModal={openPlayerPickModal}
                        makeSelection={makeSelection}
                        removeSelection={removeSelection}
                    />
                );
            }

            return (
                <MaxWidthWrapper>
                    <Box style={styles.playerPicksContainer}>
                        <PlayerItem
                            eventInfo={eventInfo}
                            player={item}
                            key={`player-${item.id}`}
                            filter={filterType}
                            projectionType={section.projectionType}
                            testID={sortedPlayers.indexOf(item).toString()}
                            openPlayerPickModal={openPlayerPickModal}
                            makeSelection={makeSelection}
                            removeSelection={removeSelection}
                            analyticsTag={analyticsTag}
                        />
                    </Box>
                </MaxWidthWrapper>
            );
        },
        [
            analyticsTag,
            filterType,
            makeSelection,
            openPlayerPickModal,
            removeSelection,
            showTile,
            sortedEventsDetails,
            sortedPlayers,
        ]
    );

    const renderSectionHeader = useCallback(
        ({ section }: { section: ListSection }) => {
            const multipleSections = groupedPlayersByProjections.length > 1;
            const nonRegular = section.projectionType !== ProjectionType.Regular;
            const shouldDisplayInfoIcon =
                section.projectionType !== ProjectionType.Regular ||
                (filterType === Filters.Projection && playerProjectionLabel === FANTASY_PTS_PROJECTION);

            if (multipleSections || nonRegular) {
                return (
                    <MaxWidthWrapper>
                        <Row mt={'s20'} mb={'s8'} ml={'s16'} alignItems={'center'}>
                            <Text variant={'headlineMedium'}>{section.title}</Text>
                            <TouchableBox ml={'s8'} onPress={() => handleProjectionTypePress(section.projectionType)}>
                                {shouldDisplayInfoIcon ? <InfoIcon color={designSystem.colors.gray3} /> : null}
                            </TouchableBox>
                        </Row>
                    </MaxWidthWrapper>
                );
            } else {
                return null;
            }
        },
        [filterType, groupedPlayersByProjections.length, handleProjectionTypePress, playerProjectionLabel]
    );

    if (!data || fetching) {
        return (
            <View style={[common.justifyCenter, { paddingTop: paddingTop + 60 }]}>
                <Loading />
            </View>
        );
    }

    if (sortedPlayers.length === 0 && isWeb) {
        // there's a bug in section list where it doesn't render the next page when scrolling
        // not rendering the list when there's no data somehow fixes it: https://github.com/facebook/react-native/issues/39421
        return <EmptyPage loading={loading} paddingTop={paddingTop} />;
    }

    return (
        <AnimatedSectionList
            {...listProps}
            key={`${columnsCount}`}
            sections={groupedPlayersByProjections}
            ListEmptyComponent={<EmptyPage loading={loading} />}
            ItemSeparatorComponent={filterType === Filters.Projection ? null : ItemSeparator}
            renderSectionFooter={filterType === Filters.Projection ? undefined : ItemSeparator}
            keyExtractor={item => `pickItem-${item.id}`}
            renderItem={renderItem}
            stickySectionHeadersEnabled={false}
            renderSectionHeader={renderSectionHeader}
            scrollEventThrottle={16}
            {...scrollableProps}
            // we need to cast this ways animated types and react-native types are not fully compatible
            // we miss the SectionList's methods (createAnimatedComponent only adds the props to the types)
            ref={ref => {
                setStickyRef(ref as FlatList<any> | null);
                listRef.current = ref as SectionList<PlayerWithTeam, Section> | null;
            }}
            refreshControl={
                <RefreshControl
                    colors={[designSystem.colors.purple]}
                    tintColor={designSystem.colors.white}
                    refreshing={fakeLoading}
                    progressViewOffset={paddingTop}
                    onRefresh={fakeRefresh}
                />
            }
            contentContainerStyle={[
                common.grow,
                {
                    // ! move all the content down to make space for the sticky tabs
                    paddingTop,
                    // ! set the height of the available content to be size of the content + padding
                    // ! so that the tabs can be completely scrolled up or down
                    minHeight: contentHeight + translationDistance - insets.top - TAB_HEIGHT,
                    paddingBottom,
                },
            ]}
        />
    );
});

export default PlayersList;

const styles = StyleSheet.create({
    playerPicksContainer: {
        paddingLeft: 14,
        paddingRight: 16,
    },
    marginHorizontal: {
        marginHorizontal: 16,
    },
});
