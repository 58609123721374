import React, { memo } from 'react';
import { StyleSheet } from 'react-native';

import { Box } from '@/components/lib/components';
import { TeamAbbreviationSVG } from '@/feature/lobby/components/TeamAbbreviationSVG';
import { designSystem } from '@/styles/styles';

import { useResizeImage } from '../hooks/useResizeImage.web';
import '../playerImage.css';
import { GeneralImageCanvasProps, TilesImageCanvasProps } from '../types';
import {
    borderWidth,
    containerHeight,
    gradientColorYOffsetWeb,
    playerImageSize,
    playerImageYOffset,
    teamColorCircle,
    tilePaddingTop,
} from './utils';

export const TilePlayerImageCanvas = memo(
    ({
        teamColor,
        bgColor,
        source,
        hideImage,
        containerWidth,
        event,
        player,
        teamName,
        onError,
    }: GeneralImageCanvasProps & TilesImageCanvasProps) => {
        const imageSource = useResizeImage({ source, onError });

        return (
            <div style={{ width: containerWidth, ...styles.canvas }}>
                <svg width={'100%'} height={'100%'} fill="none" className="playerTeamColorBlur">
                    <g>
                        <circle
                            cx={containerWidth / 2}
                            cy={gradientColorYOffsetWeb}
                            r={teamColorCircle.radius}
                            fill={teamColor || designSystem.colors[bgColor]}
                        />
                    </g>
                </svg>
                <Box position="absolute" top={-borderWidth} alignItems={'center'}>
                    <TeamAbbreviationSVG
                        teamName={teamName || ''}
                        width={containerWidth}
                        height={48}
                        player={player}
                        event={event}
                    />
                </Box>
                {!hideImage && imageSource ? (
                    <div style={styles.imageContainer}>
                        <img
                            alt=""
                            src={imageSource}
                            className="playerImage"
                            style={{ width: playerImageSize.width, height: playerImageSize.height }}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
);

const styles = StyleSheet.create({
    canvas: {
        position: 'absolute',
        top: -tilePaddingTop - borderWidth,
        height: containerHeight + playerImageYOffset + borderWidth,
    },

    imageContainer: {
        position: 'absolute',
        top: playerImageYOffset,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
