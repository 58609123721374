export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
};

export type BoostedMultiplier = {
    __typename?: 'BoostedMultiplier';
    multiplier: Scalars['Float']['output'];
    numberOfBoostedPicks: Scalars['Int']['output'];
    numberOfPicks: Scalars['Int']['output'];
    toWin: Scalars['Float']['output'];
};

export enum CardSegment {
    EarlyPrelims = 'EARLY_PRELIMS',
    MainCard = 'MAIN_CARD',
    Prelims = 'PRELIMS',
}

export enum CompetitionType {
    Tournament = 'TOURNAMENT',
    Versus = 'VERSUS',
}

export enum Currency {
    Fre = 'FRE',
    Usd = 'USD',
}

export type DataFeedSourceId = {
    __typename?: 'DataFeedSourceId';
    id: Scalars['String']['output'];
    source: EventIdSource;
};

export type DynamicMultiplier = {
    __typename?: 'DynamicMultiplier';
    multiplier: Scalars['Float']['output'];
    numberOfPicks: Scalars['Int']['output'];
    toWin: Scalars['Float']['output'];
    winningPicks: Scalars['Int']['output'];
};

export type Entry = {
    amount: Scalars['Float']['input'];
    currency?: InputMaybe<Currency>;
    entryRulesId?: InputMaybe<Scalars['String']['input']>;
    gameMode?: InputMaybe<GameMode>;
    gameType?: InputMaybe<GameType>;
    geoInformation: GeoInformation;
    picks: Array<Pick>;
    userId?: InputMaybe<Scalars['String']['input']>;
    username?: InputMaybe<Scalars['String']['input']>;
};

export type EntryError = {
    __typename?: 'EntryError';
    code: Scalars['Int']['output'];
    key: Scalars['String']['output'];
    message: Scalars['String']['output'];
    restrictedPlayerIdPairs?: Maybe<Array<Array<Scalars['String']['output']>>>;
    type: EntryErrorType;
};

export enum EntryErrorType {
    Restriction = 'RESTRICTION',
    Validation = 'VALIDATION',
}

export type EntryFilteringCriteria = {
    result?: InputMaybe<Result>;
    status?: InputMaybe<Status>;
};

export type EntryOutput = {
    __typename?: 'EntryOutput';
    amount: Scalars['Float']['output'];
    boostedMultipliers?: Maybe<Array<Maybe<BoostedMultiplier>>>;
    canBeCanceled?: Maybe<Scalars['Boolean']['output']>;
    cancelTimeFrame?: Maybe<Scalars['Int']['output']>;
    createdDate: Scalars['String']['output'];
    currency: Currency;
    dynamicMultipliers?: Maybe<Array<Maybe<DynamicMultiplier>>>;
    edgeCombos?: Maybe<Array<Array<Scalars['String']['output']>>>;
    error?: Maybe<EntryError>;
    gameMode?: Maybe<GameMode>;
    gameType?: Maybe<GameType>;
    id: Scalars['String']['output'];
    initialAmount?: Maybe<Scalars['Float']['output']>;
    initialMultiplier: Scalars['Float']['output'];
    initialToWin?: Maybe<Scalars['Float']['output']>;
    multiplier: Scalars['Float']['output'];
    multiplierWithDecimals: Scalars['Float']['output'];
    numberOfNonVoidPicks?: Maybe<Scalars['Int']['output']>;
    picks: Array<PickOutput>;
    reasonCode?: Maybe<Scalars['Int']['output']>;
    reasonText?: Maybe<Scalars['String']['output']>;
    result?: Maybe<Result>;
    settlementDate?: Maybe<Scalars['String']['output']>;
    state: State;
    status: Status;
    toWin?: Maybe<Scalars['Float']['output']>;
    userId: Scalars['String']['output'];
    winningDynamicMultiplier?: Maybe<DynamicMultiplier>;
};

export type EntryOutputPage = {
    __typename?: 'EntryOutputPage';
    content?: Maybe<Array<EntryOutput>>;
    currentPage: Scalars['Int']['output'];
    totalItems: Scalars['Int']['output'];
    totalPages: Scalars['Int']['output'];
};

export type EntryRulesOutput = {
    __typename?: 'EntryRulesOutput';
    active?: Maybe<Scalars['Boolean']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    gameMode: GameMode;
    gameType: GameType;
    id?: Maybe<Scalars['String']['output']>;
    maxNumberOfBoostedPicks?: Maybe<Scalars['Int']['output']>;
    maxNumberOfPicks: Scalars['Int']['output'];
    maxNumberOfSpecialPicks?: Maybe<Scalars['Int']['output']>;
    minNumberOfPicks: Scalars['Int']['output'];
    minNumberOfTeams: Scalars['Int']['output'];
    name?: Maybe<Scalars['String']['output']>;
};

export type EntryTradingLimitOutput = {
    __typename?: 'EntryTradingLimitOutput';
    betrBucksMultiplier: Scalars['Int']['output'];
    entryRulesId?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    maxAmount: Scalars['Int']['output'];
    minAmount: Scalars['Int']['output'];
    multiplier: Scalars['Int']['output'];
    nightFeeMaxAmount?: Maybe<Scalars['Int']['output']>;
    numberOfPicks: Scalars['Int']['output'];
};

export type EntryValidation = {
    amount: Scalars['Float']['input'];
    currency: Currency;
    entryRulesId?: InputMaybe<Scalars['String']['input']>;
    picks: Array<Pick>;
};

export type EntryValidationOutput = {
    __typename?: 'EntryValidationOutput';
    amount: Scalars['Float']['output'];
    boostedMultipliers?: Maybe<Array<Maybe<BoostedMultiplier>>>;
    currency: Currency;
    dynamicMultipliers?: Maybe<Array<Maybe<DynamicMultiplier>>>;
    edgeCombos?: Maybe<Array<Array<Scalars['String']['output']>>>;
    entryRulesId?: Maybe<Scalars['String']['output']>;
    errors: Array<EntryError>;
    gameMode?: Maybe<GameMode>;
    gameType?: Maybe<GameType>;
    maxAmount: Scalars['Float']['output'];
    maxNumberOfBoostedPicks: Scalars['Int']['output'];
    maxNumberOfPicks: Scalars['Int']['output'];
    maxNumberOfSpecialPicks: Scalars['Int']['output'];
    minAmount: Scalars['Float']['output'];
    minNumberOfPicks: Scalars['Int']['output'];
    minNumberOfTeams: Scalars['Int']['output'];
    multipliers: Array<Scalars['Float']['output']>;
    p2pGuaranteedPrize?: Maybe<Scalars['Float']['output']>;
    perfectBoostedMultiplier?: Maybe<Scalars['Float']['output']>;
    perfectRegularMultiplier?: Maybe<Scalars['Float']['output']>;
    remaining: Array<RemainingPickAmountType>;
    status: EntryValidationStatus;
    toWin: Scalars['Float']['output'];
};

export enum EntryValidationStatus {
    Error = 'ERROR',
    Incomplete = 'INCOMPLETE',
    Success = 'SUCCESS',
}

export type Event = {
    __typename?: 'Event';
    /** @deprecated This information is not needed on FE side anymore, it should not be migrated to the new structure. */
    cardSegment?: Maybe<CardSegment>;
    date: Scalars['String']['output'];
    headerImage?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    status: EventStatus;
    teams: Array<Team>;
    venueDetails?: Maybe<VenueDetails>;
};

export type EventAttribute = {
    __typename?: 'EventAttribute';
    key?: Maybe<EventAttributesType>;
    value: Scalars['String']['output'];
};

export enum EventAttributesType {
    Name = 'NAME',
    RoundNumber = 'ROUND_NUMBER',
    ShortName = 'SHORT_NAME',
    TournamentEndDateTime = 'TOURNAMENT_END_DATE_TIME',
    TournamentId = 'TOURNAMENT_ID',
    TournamentStartDateTime = 'TOURNAMENT_START_DATE_TIME',
}

export enum EventIdSource {
    BetGenius = 'BET_GENIUS',
    BetRadar = 'BET_RADAR',
    Pam = 'PAM',
    SportsData = 'SPORTS_DATA',
}

export enum EventStatus {
    Canceled = 'CANCELED',
    Delayed = 'DELAYED',
    EndOfRound = 'END_OF_ROUND',
    EndRd1 = 'END_RD1',
    EndRd2 = 'END_RD2',
    EndRd3 = 'END_RD3',
    EndRd4 = 'END_RD4',
    EndRd5 = 'END_RD5',
    Final = 'FINAL',
    Forfeit = 'FORFEIT',
    Intros = 'INTROS',
    InProgress = 'IN_PROGRESS',
    NotNecessary = 'NOT_NECESSARY',
    Overtime = 'OVERTIME',
    Paused = 'PAUSED',
    Postponed = 'POSTPONED',
    PreFight = 'PRE_FIGHT',
    Scheduled = 'SCHEDULED',
    Shootout = 'SHOOTOUT',
    Suspended = 'SUSPENDED',
    Walkouts = 'WALKOUTS',
}

export type EventV2 = {
    attributes?: Maybe<Array<Maybe<EventAttribute>>>;
    competitionType: CompetitionType;
    dataFeedSourceIds: Array<Maybe<DataFeedSourceId>>;
    date: Scalars['String']['output'];
    dedicated: Scalars['Boolean']['output'];
    headerImage?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    league: League;
    name?: Maybe<Scalars['String']['output']>;
    playerStructure: PlayerStructure;
    sport: Sport;
    status: EventStatus;
    venueDetails?: Maybe<VenueDetails>;
};

export enum GameMode {
    Dynamic = 'DYNAMIC',
    Perfect = 'PERFECT',
}

export enum GameType {
    House = 'HOUSE',
    P2P = 'P2P',
}

export type GeoInformation = {
    geoToken: Scalars['String']['input'];
    ip: Scalars['String']['input'];
    state: Scalars['String']['input'];
};

export type IndividualTournamentEvent = EventV2 & {
    __typename?: 'IndividualTournamentEvent';
    attributes?: Maybe<Array<Maybe<EventAttribute>>>;
    competitionType: CompetitionType;
    dataFeedSourceIds: Array<Maybe<DataFeedSourceId>>;
    date: Scalars['String']['output'];
    dedicated: Scalars['Boolean']['output'];
    headerImage?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    league: League;
    name?: Maybe<Scalars['String']['output']>;
    playerStructure: PlayerStructure;
    players: Array<Player>;
    sport: Sport;
    status: EventStatus;
    venueDetails?: Maybe<VenueDetails>;
};

export type IndividualVersusEvent = EventV2 & {
    __typename?: 'IndividualVersusEvent';
    attributes?: Maybe<Array<Maybe<EventAttribute>>>;
    competitionType: CompetitionType;
    dataFeedSourceIds: Array<Maybe<DataFeedSourceId>>;
    date: Scalars['String']['output'];
    dedicated: Scalars['Boolean']['output'];
    headerImage?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    league: League;
    name?: Maybe<Scalars['String']['output']>;
    playerStructure: PlayerStructure;
    players: Array<Player>;
    sport: Sport;
    status: EventStatus;
    venueDetails?: Maybe<VenueDetails>;
};

export type Jurisdiction = {
    __typename?: 'Jurisdiction';
    name: Scalars['String']['output'];
    symbol: Scalars['String']['output'];
};

export enum League {
    Cbb = 'CBB',
    Cfb = 'CFB',
    Mlb = 'MLB',
    Nba = 'NBA',
    Nfl = 'NFL',
    NflFullSeason = 'NFL_FULL_SEASON',
    Nhl = 'NHL',
    Pga = 'PGA',
    /** @deprecated Not used anymore for testing purposes. */
    Simulation = 'SIMULATION',
    Ufc = 'UFC',
}

export type LeagueConfig = {
    __typename?: 'LeagueConfig';
    enabled: Scalars['Boolean']['output'];
    icon?: Maybe<Scalars['String']['output']>;
    jurisdictions: Array<Jurisdiction>;
    label: Scalars['String']['output'];
    league: League;
    order: Scalars['Int']['output'];
    playerProfileBackgroundColor?: Maybe<Scalars['String']['output']>;
    positionFilters: Array<PositionFilters>;
};

export type MarketOption = {
    marketOptionId: Scalars['String']['input'];
    outcome: Outcome;
};

export type MarketOptionOutput = {
    __typename?: 'MarketOptionOutput';
    marketOptionId: Scalars['String']['output'];
    outcome: Outcome;
};

export enum MarketStatus {
    Opened = 'OPENED',
    Suspended = 'SUSPENDED',
}

export type Mutation = {
    __typename?: 'Mutation';
    addFavoritePlayerForUser: Array<Scalars['String']['output']>;
    cancelEntry: EntryOutput;
    createEntry: EntryOutput;
    createSharedEntry?: Maybe<SharedEntry>;
    removeFavoritePlayerForUser: Array<Scalars['String']['output']>;
};

export type MutationAddFavoritePlayerForUserArgs = {
    playerId: Scalars['String']['input'];
    userId: Scalars['String']['input'];
};

export type MutationCancelEntryArgs = {
    id: Scalars['String']['input'];
};

export type MutationCreateEntryArgs = {
    entry: Entry;
};

export type MutationCreateSharedEntryArgs = {
    entryId: Scalars['String']['input'];
};

export type MutationRemoveFavoritePlayerForUserArgs = {
    playerId: Scalars['String']['input'];
    userId: Scalars['String']['input'];
};

export enum Outcome {
    Less = 'LESS',
    More = 'MORE',
}

export type PageableInput = {
    page: Scalars['Int']['input'];
    size: Scalars['Int']['input'];
};

export type Pick = {
    eventId: Scalars['String']['input'];
    /** @deprecated Projection field should be used instead. */
    isLive?: InputMaybe<Scalars['Boolean']['input']>;
    league: League;
    marketId?: InputMaybe<Scalars['String']['input']>;
    /** @deprecated Projection field should be used instead. */
    nonRegularProjectionPercentage?: InputMaybe<Scalars['Int']['input']>;
    /** @deprecated Projection field should be used instead. */
    nonRegularProjectionValue?: InputMaybe<Scalars['Float']['input']>;
    /** @deprecated Projection field should be used instead. */
    option?: InputMaybe<MarketOption>;
    outcome: Outcome;
    playerId: Scalars['String']['input'];
    projection?: InputMaybe<ProjectionInput>;
    projectionName: Scalars['String']['input'];
    /** @deprecated Projection field should be used instead. */
    projectionType?: InputMaybe<ProjectionType>;
    projectionValue: Scalars['Float']['input'];
    /** @deprecated Was renamed to nonRegular */
    specialProjectionPercentage?: InputMaybe<Scalars['Int']['input']>;
    /** @deprecated Was renamed to nonRegular */
    specialProjectionValue?: InputMaybe<Scalars['Float']['input']>;
    sport: Sport;
    teamId?: InputMaybe<Scalars['String']['input']>;
};

export type PickOutput = {
    __typename?: 'PickOutput';
    currentValue?: Maybe<Scalars['Float']['output']>;
    eventDate: Scalars['String']['output'];
    eventId: Scalars['String']['output'];
    eventStatus: EventStatus;
    league: League;
    /** @deprecated Projection field should be used instead. */
    nonRegularProjectionPercentage?: Maybe<Scalars['Int']['output']>;
    /** @deprecated Projection field should be used instead. */
    nonRegularProjectionValue?: Maybe<Scalars['Float']['output']>;
    outcome: Outcome;
    player: Player;
    projection: Projection;
    /** @deprecated Projection field should be used instead. */
    projectionKey?: Maybe<Scalars['String']['output']>;
    /** @deprecated Projection field should be used instead. */
    projectionLabel?: Maybe<Scalars['String']['output']>;
    /** @deprecated Projection field should be used instead. */
    projectionName?: Maybe<Scalars['String']['output']>;
    /** @deprecated Projection field should be used instead. */
    projectionOrder?: Maybe<Scalars['Int']['output']>;
    /** @deprecated Projection field should be used instead. */
    projectionType?: Maybe<ProjectionType>;
    /** @deprecated Projection field should be used instead. */
    projectionValue?: Maybe<Scalars['Float']['output']>;
    result?: Maybe<Result>;
    /** @deprecated Projection field should be used instead. */
    specialProjectionPercentage?: Maybe<Scalars['Int']['output']>;
    /** @deprecated Projection field should be used instead. */
    specialProjectionValue?: Maybe<Scalars['Float']['output']>;
    sport: Sport;
    teamId?: Maybe<Scalars['String']['output']>;
    teamName?: Maybe<Scalars['String']['output']>;
};

export type Player = {
    __typename?: 'Player';
    attributes?: Maybe<Array<Maybe<PlayerAttribute>>>;
    firstName: Scalars['String']['output'];
    icon: Scalars['String']['output'];
    id: Scalars['String']['output'];
    jerseyNumber?: Maybe<Scalars['Int']['output']>;
    lastName: Scalars['String']['output'];
    position: Scalars['String']['output'];
    projections: Array<Projection>;
    shownInLeaguePage: Scalars['Boolean']['output'];
    shownInLobbyPage: Scalars['Boolean']['output'];
};

export type PlayerAttribute = {
    __typename?: 'PlayerAttribute';
    key?: Maybe<PlayerAttributesType>;
    value: Scalars['String']['output'];
};

export enum PlayerAttributesType {
    CountryCode = 'COUNTRY_CODE',
    CountryIcon = 'COUNTRY_ICON',
    CountryName = 'COUNTRY_NAME',
    GolfRank = 'GOLF_RANK',
    TeeTime = 'TEE_TIME',
}

export type PlayerProp = {
    __typename?: 'PlayerProp';
    displayName: Scalars['String']['output'];
    domainName: Scalars['String']['output'];
    key: Scalars['String']['output'];
};

export type PlayerPropLimitOutput = {
    __typename?: 'PlayerPropLimitOutput';
    active?: Maybe<Scalars['Boolean']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    league?: Maybe<League>;
    maxValue: Scalars['Float']['output'];
    minValue: Scalars['Float']['output'];
    order: Scalars['Int']['output'];
    prop: Scalars['String']['output'];
};

export enum PlayerStructure {
    Individual = 'INDIVIDUAL',
    Team = 'TEAM',
}

export type Pool = {
    __typename?: 'Pool';
    entries: Array<PoolEntry>;
    id: Scalars['String']['output'];
    numberOfPicks: Scalars['Int']['output'];
    status: PoolStatus;
};

export type PoolEntry = {
    __typename?: 'PoolEntry';
    count: Scalars['Int']['output'];
    id: Scalars['String']['output'];
    numberOfNonVoidPicks?: Maybe<Scalars['Int']['output']>;
    numberOfWinningPicks?: Maybe<Scalars['Int']['output']>;
    p2pPoints?: Maybe<Scalars['Float']['output']>;
    rank?: Maybe<Scalars['Int']['output']>;
    result?: Maybe<Result>;
    status: Status;
    userExperienceLevel: UserExperienceLevel;
    username: Scalars['String']['output'];
};

export enum PoolStatus {
    Closed = 'CLOSED',
    Open = 'OPEN',
    Settled = 'SETTLED',
}

export type PositionFilters = {
    __typename?: 'PositionFilters';
    label?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
    positionDescription?: Maybe<Scalars['String']['output']>;
};

export type Projection = {
    __typename?: 'Projection';
    allowedOptions?: Maybe<Array<MarketOptionOutput>>;
    /** @deprecated Migrated to allowedOptions */
    allowedOutcomes: Array<Outcome>;
    currentValue?: Maybe<Scalars['Float']['output']>;
    isLive?: Maybe<Scalars['Boolean']['output']>;
    key: Scalars['String']['output'];
    label: Scalars['String']['output'];
    marketId?: Maybe<Scalars['String']['output']>;
    marketStatus?: Maybe<MarketStatus>;
    name: Scalars['String']['output'];
    nonRegularPercentage?: Maybe<Scalars['Int']['output']>;
    nonRegularValue?: Maybe<Scalars['Float']['output']>;
    order: Scalars['Int']['output'];
    /** @deprecated Was renamed to nonRegular */
    specialPercentage?: Maybe<Scalars['Int']['output']>;
    /** @deprecated Was renamed to nonRegular */
    specialValue?: Maybe<Scalars['Float']['output']>;
    type: ProjectionType;
    value: Scalars['Float']['output'];
};

export type ProjectionInput = {
    isLive?: InputMaybe<Scalars['Boolean']['input']>;
    name: Scalars['String']['input'];
    nonRegularPercentage?: InputMaybe<Scalars['Int']['input']>;
    nonRegularValue?: InputMaybe<Scalars['Float']['input']>;
    option?: InputMaybe<MarketOption>;
    type?: InputMaybe<ProjectionType>;
    value: Scalars['Float']['input'];
};

export enum ProjectionType {
    Boosted = 'BOOSTED',
    Regular = 'REGULAR',
    Special = 'SPECIAL',
}

export type Query = {
    __typename?: 'Query';
    getAllEntries: Array<EntryOutput>;
    getAllEntryRules?: Maybe<Array<EntryRulesOutput>>;
    getAllEntryTradingLimits: Array<EntryTradingLimitOutput>;
    getAllPlayerPropLimitsByLeague: Array<PlayerPropLimitOutput>;
    getEntry?: Maybe<EntryOutput>;
    getEntryRules: EntryRulesOutput;
    getEntryTradingLimit: EntryTradingLimitOutput;
    /** @deprecated This query should no longer be used and V2 should be the default now */
    getEventById: Event;
    getEventByIdV2: EventV2;
    /** @deprecated This query should no longer be used and V2 should be the default now */
    getEventsByIds: Array<Event>;
    getEventsByIdsV2: Array<EventV2>;
    getFavoritePlayerIds: Array<Scalars['String']['output']>;
    getFilteredEntriesByPage: EntryOutputPage;
    getLeagueConfigs: Array<LeagueConfig>;
    getPlayerProps: Array<PlayerProp>;
    getPoolsByEntryId: Array<Pool>;
    getSharedEntries: Array<SharedEntry>;
    getSharedEntry?: Maybe<SharedEntry>;
    getTeam?: Maybe<Team>;
    getTopTenPlayersData?: Maybe<Array<EventV2>>;
    /** @deprecated This query should no longer be used and V2 should be the default now */
    getUpcomingEventById: Event;
    getUpcomingEventByIdV2: EventV2;
    /** @deprecated This query should no longer be used and V2 should be the default now */
    getUpcomingEvents: Array<Event>;
    /** @deprecated This query should no longer be used and V2 should be the default now */
    getUpcomingEventsByIds: Array<Event>;
    getUpcomingEventsByIdsV2: Array<EventV2>;
    getUpcomingEventsV2: Array<EventV2>;
    /** @deprecated This query should no longer be used and V2 should be the default now */
    getUpcomingLobbyEvents: Array<Event>;
    getUpcomingLobbyEventsV2: Array<EventV2>;
    simulateEvent?: Maybe<Scalars['Boolean']['output']>;
    validateEntries: Array<EntryValidationOutput>;
    validateEntry: EntryValidationOutput;
    validateEntryOnEntryRules: Array<EntryValidationOutput>;
};

export type QueryGetAllPlayerPropLimitsByLeagueArgs = {
    league: League;
};

export type QueryGetEntryArgs = {
    id: Scalars['String']['input'];
};

export type QueryGetEntryTradingLimitArgs = {
    numberOfPicks: Scalars['Int']['input'];
};

export type QueryGetEventByIdArgs = {
    id: Scalars['String']['input'];
};

export type QueryGetEventByIdV2Args = {
    id: Scalars['String']['input'];
};

export type QueryGetEventsByIdsArgs = {
    ids: Array<Scalars['String']['input']>;
};

export type QueryGetEventsByIdsV2Args = {
    ids: Array<Scalars['String']['input']>;
};

export type QueryGetFavoritePlayerIdsArgs = {
    userId: Scalars['String']['input'];
};

export type QueryGetFilteredEntriesByPageArgs = {
    filter?: InputMaybe<EntryFilteringCriteria>;
    pageable: PageableInput;
};

export type QueryGetLeagueConfigsArgs = {
    league?: InputMaybe<League>;
};

export type QueryGetPoolsByEntryIdArgs = {
    entryId: Scalars['String']['input'];
};

export type QueryGetSharedEntryArgs = {
    id: Scalars['String']['input'];
};

export type QueryGetTeamArgs = {
    id: Scalars['String']['input'];
};

export type QueryGetUpcomingEventByIdArgs = {
    id: Scalars['String']['input'];
};

export type QueryGetUpcomingEventByIdV2Args = {
    id: Scalars['String']['input'];
};

export type QueryGetUpcomingEventsArgs = {
    league?: InputMaybe<League>;
};

export type QueryGetUpcomingEventsByIdsArgs = {
    ids: Array<Scalars['String']['input']>;
};

export type QueryGetUpcomingEventsByIdsV2Args = {
    ids: Array<Scalars['String']['input']>;
};

export type QueryGetUpcomingEventsV2Args = {
    league?: InputMaybe<League>;
};

export type QueryGetUpcomingLobbyEventsArgs = {
    league?: InputMaybe<League>;
};

export type QueryGetUpcomingLobbyEventsV2Args = {
    league?: InputMaybe<League>;
};

export type QuerySimulateEventArgs = {
    playsStep: Scalars['Int']['input'];
    timeStep: Scalars['Int']['input'];
};

export type QueryValidateEntriesArgs = {
    entriesValidation: Array<EntryValidation>;
};

export type QueryValidateEntryArgs = {
    entryValidation: EntryValidation;
};

export type QueryValidateEntryOnEntryRulesArgs = {
    entryRulesIds: Array<Scalars['String']['input']>;
    entryValidation: EntryValidation;
};

export type RemainingPickAmountType = {
    __typename?: 'RemainingPickAmountType';
    count: Scalars['Int']['output'];
    type: ProjectionType;
};

export enum Result {
    Loss = 'LOSS',
    Void = 'VOID',
    Win = 'WIN',
}

export type SharedEntry = {
    __typename?: 'SharedEntry';
    description: Scalars['String']['output'];
    entry: EntryOutput;
    headerImage?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    logo?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
};

export enum Sport {
    AmericanFootball = 'AMERICAN_FOOTBALL',
    Baseball = 'BASEBALL',
    Basketball = 'BASKETBALL',
    Golf = 'GOLF',
    Hockey = 'HOCKEY',
    Mma = 'MMA',
}

export enum State {
    Accepted = 'ACCEPTED',
    Processing = 'PROCESSING',
    Rejected = 'REJECTED',
}

export enum Status {
    Canceled = 'CANCELED',
    CancelRequested = 'CANCEL_REQUESTED',
    Pending = 'PENDING',
    Settled = 'SETTLED',
    SettledInternally = 'SETTLED_INTERNALLY',
    SettledPool = 'SETTLED_POOL',
    SettlementRejected = 'SETTLEMENT_REJECTED',
}

export type Subscription = {
    __typename?: 'Subscription';
    notifyOnSettledEntryOrPick: EntryOutput;
    notifyOnUpdatedPoolEntry: Array<Pool>;
};

export type SubscriptionNotifyOnSettledEntryOrPickArgs = {
    id: Scalars['String']['input'];
};

export type SubscriptionNotifyOnUpdatedPoolEntryArgs = {
    id: Scalars['String']['input'];
};

export type Team = {
    __typename?: 'Team';
    color?: Maybe<Scalars['String']['output']>;
    icon: Scalars['String']['output'];
    id: Scalars['String']['output'];
    largeIcon: Scalars['String']['output'];
    league: League;
    name: Scalars['String']['output'];
    players: Array<Player>;
    secondaryColor?: Maybe<Scalars['String']['output']>;
    sport: Sport;
};

export type TeamTournamentEvent = EventV2 & {
    __typename?: 'TeamTournamentEvent';
    attributes?: Maybe<Array<Maybe<EventAttribute>>>;
    competitionType: CompetitionType;
    dataFeedSourceIds: Array<Maybe<DataFeedSourceId>>;
    date: Scalars['String']['output'];
    dedicated: Scalars['Boolean']['output'];
    headerImage?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    league: League;
    name?: Maybe<Scalars['String']['output']>;
    playerStructure: PlayerStructure;
    sport: Sport;
    status: EventStatus;
    teams: Array<Team>;
    venueDetails?: Maybe<VenueDetails>;
};

export type TeamVersusEvent = EventV2 & {
    __typename?: 'TeamVersusEvent';
    attributes?: Maybe<Array<Maybe<EventAttribute>>>;
    competitionType: CompetitionType;
    dataFeedSourceIds: Array<Maybe<DataFeedSourceId>>;
    date: Scalars['String']['output'];
    dedicated: Scalars['Boolean']['output'];
    headerImage?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    league: League;
    name?: Maybe<Scalars['String']['output']>;
    playerStructure: PlayerStructure;
    sport: Sport;
    status: EventStatus;
    teams: Array<Team>;
    venueDetails?: Maybe<VenueDetails>;
};

export enum UserExperienceLevel {
    AllStar = 'ALL_STAR',
    Rookie = 'ROOKIE',
    Veteran = 'VETERAN',
}

export type VenueDetails = {
    __typename?: 'VenueDetails';
    city?: Maybe<Scalars['String']['output']>;
    country?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
};
