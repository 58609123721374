import React, { useRef, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { ScrollableScreen } from '@/components/ScrollableScreen';
import WebView from '@/components/Webview';
import { useFetchAndActivateFirebaseRemoteConfigs } from '@/feature/analytics/hooks/use-firebase-remote-config';
import { useLinkingStore } from '@/feature/entry-share/hooks/use-linking';
import { useUser } from '@/hooks/use-user';
import { useCoreNavigation } from '@/navigation/navigation';
import { common } from '@/styles/styles';
import { isWeb } from '@/utils/constants-platform-specific';

import { useLogin } from '../hooks/use-login';
import { useRegister } from '../hooks/use-register';
import { useTrackAuth } from '../hooks/use-track-auth';
import { AuthType, getKeycloakUrl, parseCode } from '../utils';

export type AuthenticationProps = {
    initAuthType: AuthType;
    code?: string;
    sessionState?: string;
    initialEmail?: string;
    registering?: string;
};

export const Authentication = ({
    initAuthType,
    code,
    sessionState,
    initialEmail,
    registering,
}: AuthenticationProps) => {
    const navigation = useNavigation();
    const { openLaunch } = useCoreNavigation();

    const { setAuthUrl, handleTrackAbortAuth } = useTrackAuth();

    const [authType, setAuthType] = useState<AuthType>(initAuthType);

    // Fetch and activate remote configs
    useFetchAndActivateFirebaseRemoteConfigs();

    const login = useLogin(authType === 'login', code, sessionState, !!registering);
    useRegister(authType === 'register', code, sessionState);

    const { authorize, loading } = useUser();
    const hasAuthenticatedStarted = useRef(false);

    const oneLinkData = useLinkingStore(state => state.oneLinkData);

    const handleUrlChange = (url: string) => {
        setAuthUrl(url);
        switch (true) {
            case url.includes('auth'):
                setAuthType('login');
                break;
            case url.includes('registration'):
                setAuthType('register');
                break;
            case url.includes('openid-configuration?session_state'): // on authentication success, we get redirected to this url
                const authCode = parseCode(url); // parse query params
                if (authCode && !hasAuthenticatedStarted.current) {
                    hasAuthenticatedStarted.current = true;
                    if (authType === 'login') {
                        login(authCode);
                    } else if (authType === 'register') {
                        authorize(authCode);
                    }
                }
                return false;
            default:
                // open support urls in modal webview
                const regexSupportUrl = new RegExp('https://support.betr.app/.*');
                if (regexSupportUrl.test(url)) {
                    navigation.navigate('ModalWebView', { uri: url });
                    return false;
                }
        }
        return true;
    };

    const handleOnClose = () => {
        if (navigation.canGoBack()) {
            handleTrackAbortAuth();
            navigation.goBack();
        } else {
            openLaunch();
        }
    };

    const screenTitle = authType === 'login' ? 'Login' : 'Sign Up';

    if (isWeb) {
        return null;
    }

    return (
        <ScrollableScreen
            screenNavBarProps={{ title: screenTitle, closeIconMode: 'close', onClose: handleOnClose }}
            scrollViewProps={{ contentContainerStyle: { flexGrow: 1 }, style: { paddingHorizontal: 0 } }}
        >
            {loading || hasAuthenticatedStarted.current ? (
                <View style={[common.flex, common.alignCenter, common.justifyCenter, common.backgroundColorDarkMask]}>
                    <ActivityIndicator color={'white'} size={'large'} />
                </View>
            ) : (
                <WebView
                    style={common.backgroundColorTransparent}
                    containerStyle={common.backgroundColorTransparent}
                    cacheEnabled={false}
                    cacheMode={'LOAD_NO_CACHE'}
                    originWhitelist={['*']}
                    javaScriptEnabled={true}
                    thirdPartyCookiesEnabled={false} // Keep third-party cookies disabled as originally set
                    onMessage={() => {}}
                    onShouldStartLoadWithRequest={req => handleUrlChange(req.url)}
                    source={{
                        uri: getKeycloakUrl(authType, initialEmail, oneLinkData?.data.deep_link_sub1),
                    }}
                />
            )}
        </ScrollableScreen>
    );
};
