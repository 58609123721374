import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';
import { GestureDetector } from 'react-native-gesture-handler';
import Animated, { Extrapolation, interpolate, useAnimatedStyle } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useRoute } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import { useUpcomingEventsConditionalWithPlayersQuery } from '@/api/events/query.generated';
import { EventInfo, EventInfoWithoutPlayers } from '@/api/events/types/types';
import { SCREEN_NAV_BAR_HEIGHT } from '@/components/ScreenNavBar';
import { StickyTabsProvider, useStickyTabsAnimation } from '@/components/StickyTabsProvider';
import { TOP_TAB_BAR_CONFIG, TopTabBar, TopTabBarProps } from '@/components/TopTabBar';
import { Box } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { getRemoteConfigByKey } from '@/feature/analytics/hooks/use-firebase-remote-config';
import { FirebaseRemoteSettings } from '@/feature/analytics/utils/firebaseSettings';
import { PositionTabView } from '@/feature/lobby/components/PositionTabView';
import { ProjectionsTabView } from '@/feature/lobby/components/ProjectionsTabView';
import { DEFAULT_SECTIONS_ORDER, TABS_NAMES, sectionsUtilsData } from '@/feature/lobby/utils/tabs/sectionUtils';
import { PickemSportTabParamsList, SECTION_SCREENS } from '@/feature/lobby/utils/tabs/types';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useResumeEffect } from '@/hooks/use-resume';
import { PickemLobbyStackParamList } from '@/navigation/pickem/types';
import { common, designSystem } from '@/styles/styles';
import { League } from '@/types/api.generated';
import { read, save } from '@/utils/async-storage';
import { NFL_FULL_SEASON } from '@/utils/constants';
import { isUFC } from '@/utils/league';
import { sortEvents } from '@/utils/sortEvents';

import { EVENT_HEADER_HERO_IMAGE_HEIGHT, EventHeader } from '../components/EventHeader';
import { EventsList } from '../components/EventsList';
import { LeagueNavigationBar } from '../components/LeagueNavigationBar';
import { EmptyPage } from '../components/PlayersList';
import { useLeagueSelector } from '../hooks/use-league-selector';

const styles = StyleSheet.create({
    toRight: {
        paddingHorizontal: 0,
    },

    tabContainer: {
        backgroundColor: designSystem.colors.gray8,
    },
    headerContainer: {
        position: 'absolute',
        width: '100%',
    },
});

const Tab = createMaterialTopTabNavigator<PickemSportTabParamsList>();

export const PickemSportScreen = () => {
    const { params } = useRoute<PickemLobbyParamsList['route']>();

    return (
        // we need to reset the provider every team we change the league, hence we use the league as a key
        <StickyTabsProvider headerHeight={0} key={params.league}>
            <PickemSportStickyTabs />
        </StickyTabsProvider>
    );
};

export type PickemLobbyParamsList = StackScreenProps<PickemLobbyStackParamList, 'PickemSportScreen'>;

export const PickemSportStickyTabs = () => {
    const { params } = useRoute<PickemLobbyParamsList['route']>();

    const timer = useRef<NodeJS.Timeout | undefined>();
    const [hideLoader, setHideLoader] = useState<boolean>(false);
    const { showLeagueSelector, selectedLeague } = useLeagueSelector(params.league);
    const [initialTabName, setInitialTabName] = useState<SECTION_SCREENS | null>(null);

    const { data, loading, execute } = useEventsData(selectedLeague?.id);

    const refresh = useCallback(
        (silent?: boolean) => {
            if (selectedLeague?.id) {
                setHideLoader(silent ?? false);
                execute({ requestPolicy: 'network-only' });
            }
        },
        [execute, selectedLeague?.id]
    );

    const refreshSilent = useCallback(() => refresh(true), [refresh]);

    const feed: (EventInfo | EventInfoWithoutPlayers)[] = useMemo(
        () => data?.getUpcomingEventsV2 ?? [],
        [data?.getUpcomingEventsV2]
    );
    const sortedEvents = useMemo(() => feed.sort((event1, event2) => sortEvents(event1, event2)), [feed]);

    useResumeEffect(
        useCallback(() => {
            if (selectedLeague?.id) {
                refreshSilent();
                BetrAnalytics.trackScreenView('LeaguePage', { league: selectedLeague.id });
            }
        }, [refreshSilent, selectedLeague?.id])
    );

    // Ideally here we'd subscribe to an event
    // but as a short term solution I guess this would do it
    // 😬
    useEffect(() => {
        clearInterval(timer.current);

        timer.current = setInterval(() => {
            refreshSilent();
        }, 3e4);

        return () => {
            clearInterval(timer.current);
        };
    });

    const prismicSectionsOrderingOrDefault = useJurisdictionStore(
        state => state.jurisdictionSettings?.productConfig?.sectionsOrdering?.items ?? DEFAULT_SECTIONS_ORDER
    );

    const customOrderingEnabled = getRemoteConfigByKey(
        FirebaseRemoteSettings.ENABLE_FANTASY_CUSTOM_SECTIONS_ORDER
    ).asBoolean();

    const sectionsOrdering = customOrderingEnabled ? prismicSectionsOrderingOrDefault : DEFAULT_SECTIONS_ORDER;

    const { eventPageEnabled, isUfcEventPageEnabled, firstEvent, sections, allSectionsByLeague } = sectionsUtilsData(
        sortedEvents,
        sectionsOrdering,
        selectedLeague
    );

    useEffect(() => {
        (async () => {
            const savedTabName = (await read('PickemTabName')) ?? TABS_NAMES[0];
            setInitialTabName(eventPageEnabled && !isUfcEventPageEnabled ? TABS_NAMES[1] : savedTabName);
        })();
    }, [eventPageEnabled, isUfcEventPageEnabled]);

    const {
        panGesture,
        smoothScrollHeaderStyleBelowTabsPullToRefresh,
        tabListeners,
        headerHeight,
        scrollY,
        updateHeaderHeight,
    } = useStickyTabsAnimation();

    const eventHeaderStyle = useAnimatedStyle(() => {
        return {
            opacity: interpolate(scrollY.value, [0, headerHeight], [0, 1], Extrapolation.CLAMP),
        };
    });

    const separatorStyles = useAnimatedStyle(() => {
        return {
            opacity: interpolate(scrollY.value, [0, 1], [0, 1], Extrapolation.CLAMP),
        };
    });

    const insets = useSafeAreaInsets();

    const renderStickyTabBar = useCallback(
        (props: TopTabBarProps) => {
            return (
                <Animated.View
                    style={[
                        smoothScrollHeaderStyleBelowTabsPullToRefresh,
                        {
                            backgroundColor: designSystem.colors.gray8,
                            top: headerHeight + insets.top + SCREEN_NAV_BAR_HEIGHT,
                        },
                    ]}
                >
                    <TopTabBar {...props} customEdges={[]} />
                </Animated.View>
            );
        },
        [headerHeight, insets.top, smoothScrollHeaderStyleBelowTabsPullToRefresh]
    );

    useEffect(() => {
        /**
         * When displaying a single event, we want to show an extended header with a hero image
         * However, we need to consider the heights of the status bar and the navigation bar when positioning the header
         * Because the custom header is behind both the status bar and the navigation bar,
         * the "sticky" header height variable excludes these heights and only includes
         * the extra height of the custom header.
         * In this way, the animation occurs only for the "excess" height of the custom header
         * */
        updateHeaderHeight(firstEvent ? EVENT_HEADER_HERO_IMAGE_HEIGHT - insets.top - SCREEN_NAV_BAR_HEIGHT : 0);
    }, [insets.top, firstEvent, updateHeaderHeight]);

    //
    const translationDistance = headerHeight + insets.top + SCREEN_NAV_BAR_HEIGHT;

    const { width, height } = useWindowDimensions();

    if (initialTabName === null || sections.length === 0) {
        return <EmptyPage loading />;
    }

    const leagueName = selectedLeague?.id === League.NflFullSeason ? NFL_FULL_SEASON : selectedLeague?.label;

    return (
        <View style={[common.noOverflow, common.flex]}>
            <Box position="absolute" top={insets.top} zIndex={2} width="100%">
                <LeagueNavigationBar
                    selectedLeagueLabel={leagueName}
                    showLeagueSelector={showLeagueSelector}
                    hasBackground={!!firstEvent}
                />
            </Box>
            <GestureDetector gesture={panGesture}>
                <Animated.View style={[common.full, smoothScrollHeaderStyleBelowTabsPullToRefresh]}>
                    <EventHeader singleEvent={firstEvent} headerOverlayStyle={eventHeaderStyle} />
                </Animated.View>
            </GestureDetector>
            {firstEvent ? null : <TopInsetBackground />}
            <Tab.Navigator
                key={`${selectedLeague?.id}-${headerHeight}`}
                tabBar={renderStickyTabBar}
                screenOptions={{
                    ...TOP_TAB_BAR_CONFIG,
                    tabBarItemStyle: {
                        width: width / sections.length,
                    },
                }}
                initialRouteName={initialTabName}
                screenListeners={({ route }) => ({
                    ...tabListeners,
                    state: async () => {
                        if (route.name !== initialTabName) {
                            await save('PickemTabName', route.name);
                        }
                    },
                })}
                style={[styles.tabContainer]}
                initialLayout={{ width, height }}
            >
                {sections.map(sectionKey => {
                    return (
                        <Tab.Screen
                            name={`PickemSport-${sectionKey}`}
                            key={sectionKey}
                            options={{
                                title: allSectionsByLeague[sectionKey]?.label,
                                tabBarTestID: `topTab-${sectionKey}`,
                            }}
                        >
                            {({ route }) => {
                                if (!selectedLeague || !data) {
                                    return null;
                                }

                                if (route.name.includes('PROJECTIONS')) {
                                    return (
                                        <ProjectionsTabView
                                            key={selectedLeague?.id}
                                            selectedLeague={selectedLeague}
                                            sortedEvents={sortedEvents}
                                            hideLoader={hideLoader}
                                            loading={loading}
                                            refresh={refresh}
                                            translationDistance={translationDistance}
                                            separatorStyles={separatorStyles}
                                        />
                                    );
                                }
                                if (route.name.includes('PLAYERS')) {
                                    return (
                                        <PositionTabView
                                            openPlayerWithId={route.params?.pickId}
                                            key={selectedLeague.id}
                                            selectedLeague={selectedLeague}
                                            sortedEvents={sortedEvents}
                                            hideLoader={hideLoader}
                                            loading={loading}
                                            refresh={refresh}
                                            translationDistance={translationDistance}
                                            separatorStyles={separatorStyles}
                                        />
                                    );
                                }
                                if (route.name.includes('GAMES')) {
                                    return (
                                        <EventsList
                                            key={selectedLeague.id}
                                            sortedEvents={sortedEvents}
                                            loading={loading}
                                            onRefresh={refresh}
                                            hideLoader={hideLoader}
                                            translationDistance={translationDistance}
                                        />
                                    );
                                }
                                return null;
                            }}
                        </Tab.Screen>
                    );
                })}
            </Tab.Navigator>
        </View>
    );
};

// This component is used to show a black background behind the status bar when no single event is available
// This component is used since if we use the our Screen component here, it will increase the overall complexity
const TopInsetBackground = () => {
    const insets = useSafeAreaInsets();
    return (
        <View
            style={[
                StyleSheet.absoluteFill,
                {
                    height: insets.top,
                    backgroundColor: designSystem.colors.gray8,
                    zIndex: designSystem.zIndex.zIndex4,
                },
            ]}
        />
    );
};

const useEventsData = (league: League | undefined) => {
    // We need to fetch the players for the UFC league, because we need the player images to be displayed
    // where normally the team logo goes.
    // For EVERY league, players are fetched when a GameCard is collapsed
    const fetchDataWithPlayers = isUFC(league);

    const [{ data, fetching: loading }, execute] = useUpcomingEventsConditionalWithPlayersQuery({
        pause: true,
        variables: { league: league!, withPlayers: fetchDataWithPlayers },
    });

    return {
        data,
        loading,
        execute,
    };
};
