import React, { useRef } from 'react';
import { RefreshControl, ScrollView, StyleSheet, View, useWindowDimensions } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { RouteProp, useRoute } from '@react-navigation/native';

import Gradient from '@/assets/icons/gradient';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { ProgressDots } from '@/components/ProgressDots';
import { Screen } from '@/components/ScreenComponent';
import { Box, BoxProps, Row } from '@/components/lib/components';
import { ShareBetInfo } from '@/feature/bet-share-sbk/components/ShareBetInfo';
import { BetSummarySection } from '@/feature/bets-sbk/components/bet-details/BetSummarySection';
import { useBetDetailQuery } from '@/feature/bets-sbk/hooks/use-bet-detail';
import { getProgressDots } from '@/feature/bets-sbk/utils/utils';
import { Separator } from '@/feature/entries-pickem/components/Separator';
import { Header } from '@/feature/entry-share/components/Header';
import { getImageStyle } from '@/feature/entry-share/screens/EntryShare';
import { useForceRefresh } from '@/feature/event-details-sbk/hooks/use-force-refresh';
import { useFetchOnResume } from '@/hooks/use-fetch-resume';
import { Product } from '@/hooks/use-jurisdiction';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';
import { isWeb, showScrollIndicator } from '@/utils/constants-platform-specific';

import { useBetShare } from '../hooks/useBetShareSBK';

type BetShareRouteProp = RouteProp<RootStackParamList, 'BetShareScreen'>;

export const BetShareScreen = () => {
    const { width } = useWindowDimensions();
    const route = useRoute<BetShareRouteProp>();
    const { bet, betId } = route.params;
    const { data, isInitialLoading, refetch } = useBetDetailQuery(betId, bet);
    useFetchOnResume(refetch);
    const { forceRefreshing, onRefresh } = useForceRefresh(refetch);

    const viewRef = useRef<View>(null);

    useBetShare(viewRef);

    const isLoading = isInitialLoading || !data;
    if (isLoading) {
        return (
            <Screen>
                <Box flex={1} justifyContent="center" alignItems="center">
                    <LoadingSpinner size={24} displayLogo={false} />
                </Box>
            </Screen>
        );
    }

    const imageStyle = getImageStyle(width);
    const progressDots = getProgressDots(data);
    const hasProgressDots = progressDots.length > 1;

    return (
        <SafeAreaView style={common.flex} edges={['top']}>
            <ScrollView
                showsVerticalScrollIndicator={showScrollIndicator}
                refreshControl={
                    <RefreshControl
                        enabled
                        colors={[designSystem.colors.white]}
                        tintColor={designSystem.colors.white}
                        refreshing={forceRefreshing}
                        onRefresh={onRefresh}
                    />
                }
            >
                <Box flex={1} backgroundColor={'gray8'} ref={viewRef}>
                    <Box alignItems={'center'}>
                        <Gradient style={[styles.image, imageStyle]} />
                    </Box>
                    <View collapsable={false} style={isWeb ? styles.contentContainer : null}>
                        <Header product={Product.Sportsbook} />
                        <Box>
                            <Row paddingHorizontal={'s16'} marginBottom={'s12'}>
                                <Separator flex={1} light={true} style={styles.separator} />
                            </Row>
                            <Row marginBottom={'s8'}>
                                <BetSummarySection
                                    bet={data}
                                    betSummarySectionProps={{
                                        betSummarySeparatorColour: 'white' as BoxProps['backgroundColor'],
                                        betSummarySeparatorOpacity: 0.1,
                                    }}
                                />
                            </Row>
                            <Row paddingHorizontal={'s16'} gap={hasProgressDots ? 's8' : 's0'}>
                                <Box flex={1} justifyContent={'center'}>
                                    <Separator light={true} style={styles.separator} />
                                </Box>
                                {hasProgressDots ? <ProgressDots dots={progressDots} /> : null}
                                <Box flex={1} justifyContent={'center'}>
                                    <Separator light={true} style={styles.separator} />
                                </Box>
                            </Row>
                        </Box>
                        <ShareBetInfo bet={data} />
                    </View>
                </Box>
            </ScrollView>
        </SafeAreaView>
    );
};

const gradientWidth = 744;

const styles = StyleSheet.create({
    image: {
        height: 216,
        width: gradientWidth,
        position: 'absolute',
        top: -1, //make sure gradient image is under the 1px border
    },
    contentContainer: {
        position: 'absolute',
        width: '100%',
    },
    separator: {
        backgroundColor: designSystem.colors.gray6,
    },
});
