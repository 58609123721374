import { useCallback } from 'react';
import { NativeModules } from 'react-native';

import { NavigationProp } from '@react-navigation/native';

import { RootStackParamList } from '@/navigation/types';
import { isIos } from '@/utils/constants-platform-specific';
import { logger } from '@/utils/logging';

import { UserInfo } from '../use-auth-user-info';
import { user } from '../use-user';

const { PaysafeVenmoSDKModule } = NativeModules;

const LOG_TAG = '[PaysafeVenmoPaymentsSDK]';

// This is to initialize the Venmo context in iOS.
// For android, it will be initialized within MainActivity.kt
export const useInitializeVenmoContext = () => {
    const initializeVenmoContext = useCallback(async (): Promise<string | undefined> => {
        if (isIos) {
            try {
                const result = await PaysafeVenmoSDKModule.initializeVenmoContext();
                return result;
            } catch (error) {
                logger.error(LOG_TAG, 'Failed to initialize Venmo context', error);
            }
        } else {
            logger.debug(LOG_TAG, 'For android, Venmo will be initialized within MainActivity.ktd');
        }
    }, []);

    return initializeVenmoContext;
};

/**
 * Handles venmo tokenization. If tokenization is successful, return a venmo payment token.
 */
export const handleTokenizePayment = async (
    amount: number,
    userInfo: UserInfo['data'],
    navigate: Omit<NavigationProp<RootStackParamList>, 'getState'>['navigate']
) => {
    const totalPrice = amount;
    const consumerId = user?.profile.sub;
    const firstName = userInfo?.first_name;
    const lastName = userInfo?.last_name;
    const email = userInfo?.email;

    try {
        const response = await PaysafeVenmoSDKModule.tokenizeVenmoPayment(
            totalPrice,
            consumerId,
            firstName,
            lastName,
            email
        );

        return response.trim();
    } catch (error) {
        logger.error(LOG_TAG, 'Tokenization Failure:', error);
        navigate('ErrorModal', {
            title: 'Error Processing Payment',
            subtitle: 'There is an error tokenizing your payment.',
            primaryButton: 'Close',
        });
    }
};
