import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { SharedValue } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { KeyPad } from '@/components/NumberPad';
import { Box } from '@/components/lib/components';
import { useAndroidBackButtonToCloseModal } from '@/feature/alerts/components/Modal';
import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { CurrencySwitcher } from '@/feature/betslip-pickem/components/CurrencySwitcher';
import { useIsBetSlipSubmitting } from '@/feature/betslip-sbk/hooks/betslip-hooks';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { useWallet } from '@/hooks/use-wallet';
import { SEPARATOR_HEIGHT, common, designSystem } from '@/styles/styles';
import { Currency } from '@/types/api.generated';
import BottomSheet, { BottomSheetScrollView } from '@gorhom/bottom-sheet';

import { useBetSlipNumPad } from './hooks/use-bet-slip-num-pad';

const styles = StyleSheet.create({
    bottomSheet: {
        borderTopColor: designSystem.colors.divider,
        borderTopWidth: SEPARATOR_HEIGHT,
    },
    indicator: {
        backgroundColor: designSystem.colors.gray4,
        marginBottom: 10,
    },
});

type BetSlipNumPadProps = {
    animatedPosition: SharedValue<number>;
    bottomHeight: number;
};

export const BetSlipNumPad = ({ animatedPosition, bottomHeight }: BetSlipNumPadProps) => {
    const { t } = useTranslation('common');
    const editBetId = useSbkBetSlipStore(state => state.editBetId);
    const showKeyboard = useSbkBetSlipStore(state => state.showKeyboard);
    const setShowKeyboard = useSbkBetSlipStore(state => state.actions.setShowKeyboard);
    const updateStakeCurrency = useSbkBetSlipStore(state => state.actions.updateStakeCurrency);
    const { onNumberPress, onDecimalPress, onBackspacePress } = useBetSlipNumPad(editBetId);
    const { betrBucks, realMoneyTotal } = useWallet();
    const toggleUseBetrBucks = useSbkBetSlipStore(state => state.actions.toggleUseBetrBucks);
    const isUsingBetrBucks = useSbkBetSlipStore(state => state.useBetrBucks);
    const isBetSlipSubmitting = useIsBetSlipSubmitting();

    const currencies = [
        { currency: Currency.Usd, amount: realMoneyTotal, title: t('cash') },
        { currency: Currency.Fre, amount: betrBucks, title: t('betr_bucks') },
    ];
    const bottomSheetRef = useRef<BottomSheet>(null);

    useEffect(() => {
        if (showKeyboard) {
            bottomSheetRef.current?.expand();
        } else {
            bottomSheetRef.current?.snapToIndex(0);
        }
    }, [showKeyboard]);

    const { handleSheetPositionChange } = useAndroidBackButtonToCloseModal(true);

    const handleSheetChange = (index: number) => {
        if (index === 0) {
            setShowKeyboard(false);
        }
        handleSheetPositionChange(index);
    };
    const { bottom } = useSafeAreaInsets();

    return (
        <BottomSheet
            ref={bottomSheetRef}
            index={showKeyboard ? 1 : 0}
            onChange={handleSheetChange}
            snapPoints={[1]}
            animateOnMount={false}
            animatedPosition={animatedPosition}
            enableDynamicSizing
            enableOverDrag={false}
            backgroundStyle={{ backgroundColor: designSystem.colors.gray8 }}
            handleIndicatorStyle={styles.indicator}
            style={styles.bottomSheet}
            bottomInset={bottom + bottomHeight}
            accessible={false}
        >
            <BottomSheetScrollView scrollEnabled={false} style={common.paddingHorizontal}>
                <CurrencySwitcher
                    disabled={isBetSlipSubmitting}
                    onCurrencyPress={(currency: Currency) => {
                        toggleUseBetrBucks();
                        GASbkEvents.toggleCurrency();
                        if (editBetId) {
                            updateStakeCurrency(editBetId, currency);
                        }
                    }}
                    activeCurrency={isUsingBetrBucks ? Currency.Fre : Currency.Usd}
                    currencies={currencies}
                    autoSwitchToNonZero={true}
                />
                <Box mt={'s10'}>
                    <KeyPad
                        onBackspacePress={onBackspacePress}
                        onDecimalPress={onDecimalPress}
                        onNumberPress={onNumberPress}
                    />
                </Box>
            </BottomSheetScrollView>
        </BottomSheet>
    );
};
