import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RESULTS, checkNotifications } from 'react-native-permissions';

import PushPrimerImg from '@/assets/images/pushPrimer';
import { Button } from '@/components/ButtonComponent';
import { Screen } from '@/components/ScreenComponent';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { openLocationSettings } from '@/feature/permissions';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useResumeEffect } from '@/hooks/use-resume';
import { useCoreNavigation } from '@/navigation/navigation';
import { read, save } from '@/utils/async-storage';

import { PUSH_PRIMER_IGNORE_PERIOD, PUSH_PRIMER_SKIP_COUNT } from '../hooks/use-push-primer-eligibility';

export const PushPrimerScreen = () => {
    const { t } = useTranslation(['push_primer', 'common']);
    const { openHome } = useCoreNavigation();

    const handleNotificationStatus = useCallback(() => {
        (async () => {
            const { status } = await checkNotifications();
            if (status === RESULTS.GRANTED) {
                openHome();
            }
        })();
    }, [openHome]);

    useResumeEffect(handleNotificationStatus);

    const onPressSetting = async () => {
        await openLocationSettings();
    };
    const onSkip = async () => {
        const skipCount = Number((await read(PUSH_PRIMER_SKIP_COUNT)) || 0);
        if (!skipCount) {
            // first skip sets the ignore period to 24 hours
            const ignoreUntil = Date.now() + 24 * 60 * 60 * 1000;
            await save(PUSH_PRIMER_SKIP_COUNT, '1');
            await save(PUSH_PRIMER_IGNORE_PERIOD, new Date(ignoreUntil).toISOString());
        } else if (skipCount > 0) {
            // starting from the second skip, the ignore period is set to 1 year
            const ignoreUntil = Date.now() + 365 * 24 * 60 * 60 * 1000;
            await save(PUSH_PRIMER_SKIP_COUNT, '2');
            await save(PUSH_PRIMER_IGNORE_PERIOD, new Date(ignoreUntil).toISOString());
        }

        openHome();
    };

    return (
        <Screen edges={['top', 'bottom']}>
            <MaxWidthWrapper flex={1} paddingHorizontal={'s16'} position={'relative'}>
                <Box flex={1} justifyContent={'space-between'}>
                    <Box justifyContent={'flex-start'} alignItems={'center'} flex={1}>
                        <PushPrimerImg />
                        <Text variant={'displayMedium'} textAlign={'center'} fontWeight={'800'}>
                            {t('title')}
                        </Text>
                        <SizedBox value={12} />
                        <Text
                            variant={'bodyMedium'}
                            textAlign={'center'}
                            color="gray2"
                            fontFamily={'SF Pro'}
                            fontStyle={'normal'}
                        >
                            {t('description')}
                        </Text>
                    </Box>

                    <Box gap="s16">
                        <Button
                            label={t('turn_on_push_notifications')}
                            hierarchy={'primary'}
                            onPress={onPressSetting}
                        />
                        <Button label={t('skip_for_now')} onPress={onSkip} />
                    </Box>
                </Box>
            </MaxWidthWrapper>
        </Screen>
    );
};
