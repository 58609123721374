import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableWithoutFeedback } from 'react-native';

import LockIcon from '@/assets/icons/lock';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { useIsBetSlipSubmitting, useIsOptionSuspended, useSelection } from '@/feature/betslip-sbk/hooks/betslip-hooks';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { BetIds } from '@/feature/betslip-sbk/utils/betslip-utils';
import { SEPARATOR_HEIGHT, designSystem } from '@/styles/styles';

import { BetInputs } from '../bet-inputs/BetInputs';
import { SinglesCardInfo } from './SinglesCardInfo';
import { SinglesOdds } from './SinglesOdds';

export const SinglesCard = ({ selectionId, isLastItem = false }: { selectionId: string; isLastItem?: boolean }) => {
    const { t } = useTranslation('betslip_sbk');
    const setShowKeyboard = useSbkBetSlipStore(state => state.actions.setShowKeyboard);
    const selection = useSelection(selectionId);
    const betId = BetIds.SINGLE(selection);
    const isBetSlipSubmitting = useIsBetSlipSubmitting();
    const isSuspended = useIsOptionSuspended(selectionId);
    const removeSelection = useSbkBetSlipStore(state => state.actions.removeSelection);

    return (
        <TouchableWithoutFeedback disabled={isSuspended} onPress={() => setShowKeyboard(false)} accessible={false}>
            <Box pb="s4" mb="s18" borderBottomWidth={isLastItem ? 0 : SEPARATOR_HEIGHT} borderBottomColor="divider">
                <SinglesCardInfo
                    selectionId={selectionId}
                    isSuspended={isSuspended}
                    isBetSlipSubmitting={isBetSlipSubmitting}
                    onDelete={() => {
                        GASbkEvents.removeSelection();
                        removeSelection(selectionId);
                    }}
                    oddsColumn={
                        <SinglesOdds
                            optionId={selection.optionId}
                            variant="titleMedium"
                            color={isSuspended ? 'gray4' : 'gray1'}
                        />
                    }
                    borderColor={designSystem.colors.gray8}
                />
                {isSuspended ? (
                    <Box
                        mb="s14"
                        px="s12"
                        py="s18"
                        borderColor="gray6"
                        borderWidth={1}
                        borderRadius="r10"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="row"
                    >
                        <LockIcon />
                        <Text variant="titleMedium" ml="s8" color="gray4">
                            {t('market_suspended')}
                        </Text>
                    </Box>
                ) : (
                    <BetInputs betId={betId} />
                )}
            </Box>
        </TouchableWithoutFeedback>
    );
};
