import { useCallback, useEffect } from 'react';
import { AppState, AppStateStatus } from 'react-native';

import { useIsNativeSportsbook } from '@/hooks/use-is-native-sportsbook';
import { useWebsocket } from '@/utils/websocket/provider';
import { MatchUpdateMessage } from '@/utils/websocket/types';
import { runStoreUpdate } from '@/utils/zustand';

import { useSbkBetSlipStore } from '../use-sbk-betslip-store';

type EventListener = (e: { data: MatchUpdateMessage }) => void;

const eventHandlers = new Map<string, EventListener>();

/**
 * Subscribes to unique events (`event:{eventId}` channel) and listens to `match_update` messages for the bet slip
 * Handles match updates
 */
export const useSubscribeEvents = () => {
    const { websocketService, connected, authenticated } = useWebsocket();
    const eventIds = useSbkBetSlipStore(state => state.eventOrder);
    const handleMatchUpdateMessage = useSbkBetSlipStore(state => state.actions.handleMatchUpdateMessage);
    const updateAllEventDetails = useSbkBetSlipStore(state => state.actions.updateAllEventDetails);
    const isNativeSportsbook = useIsNativeSportsbook();

    const unsubscribeListener = useCallback(
        (eventId: string, listener: EventListener) => {
            websocketService?.unsubscribePublic<'eventMatchUpdate'>(
                'event:{eventId}',
                { eventId },
                'match_update',
                listener
            );
            eventHandlers.delete(eventId);
        },
        [websocketService]
    );

    const subscribeAll = useCallback(() => {
        // subscribe new handlers
        eventIds.forEach(eventId => {
            if (!eventHandlers.has(eventId)) {
                const handler = (data: MatchUpdateMessage) => {
                    runStoreUpdate(() => handleMatchUpdateMessage(data));
                };
                const listener = websocketService?.subscribePublic<'eventMatchUpdate'>(
                    'event:{eventId}',
                    { eventId },
                    'match_update',
                    handler
                );
                if (listener) {
                    eventHandlers.set(eventId, listener);
                }
            }
        });

        // unsubscribe unused handlers
        eventHandlers.forEach((listener, eventId) => {
            if (!eventIds.includes(eventId)) {
                unsubscribeListener(eventId, listener);
            }
        });
    }, [handleMatchUpdateMessage, eventIds, unsubscribeListener, websocketService]);

    useEffect(() => {
        if (isNativeSportsbook && authenticated && connected) {
            subscribeAll();
        }
    }, [authenticated, connected, isNativeSportsbook, subscribeAll]);

    const unsubscribeAll = useCallback(() => {
        eventHandlers.forEach((listener, eventId) => {
            unsubscribeListener(eventId, listener);
        });
    }, [unsubscribeListener]);

    // Clean up subscriptions
    useEffect(() => {
        return () => {
            unsubscribeAll();
        };
    }, [unsubscribeAll]);

    useEffect(() => {
        // When the user leaves the app, we want to unsubscribe from all the event channels
        // When the user returns to the app, we want to resubscribe to all the event channels and fetch all the latest event details
        const handleAppStateChange = (nextAppState: AppStateStatus) => {
            if (nextAppState === 'background') {
                unsubscribeAll();
            } else if (nextAppState === 'active') {
                subscribeAll();
                updateAllEventDetails();
            }
        };
        const subscription = AppState.addEventListener('change', handleAppStateChange);

        return () => {
            subscription.remove();
        };
    }, [subscribeAll, unsubscribeAll, updateAllEventDetails]);
};
