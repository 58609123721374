import { URLS, createJurisdictionHeaders } from '@/data';
import { FeaturedBet } from '@/feature/lobby-sbk/components/FeaturedBets/types';
import { cacheControlHeader } from '@/utils/constants-platform-specific';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';

export type GetFeaturedBetsResponse = {
    data: FeaturedBet[];
};

export const getFeaturedBets = async (leagueId?: string, eventId?: string): Promise<GetFeaturedBetsResponse> => {
    const queryParams = new URLSearchParams();
    if (leagueId) {
        queryParams.append('league_id', leagueId);
    }
    if (eventId) {
        queryParams.append('event_id', eventId);
    }

    const response = await fetch(`${URLS.CHAMELON_API_URL}/events/featured-bets?${queryParams.toString()}`, {
        method: 'GET',
        headers: {
            ...createJurisdictionHeaders(),
            ...cacheControlHeader,
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }

    return data;
};

export const featuredBetsKeys = {
    all: ['featured-bets'],
    featured: (leagueId?: string) => [...featuredBetsKeys.all, 'league', leagueId] as const,
};

export const useFeaturedBets = () => {
    return useQuery(featuredBetsKeys.all, () => getFeaturedBets(), {
        // TODO filter out SGP and SGP+ bets until backend supports it
        select: data => data.data.filter(bet => bet.bet_type !== 'SGP' && bet.bet_type !== 'SGP+'),
    });
};

const isLeagueIdMatching = (featuredBet: FeaturedBet, id: string): boolean => {
    return featuredBet.events.some(event => event.league.id === id);
};

const getPlaceholderDataForLeague = (queryClient: QueryClient, leagueId: string) => {
    const featuredBetsResponse = queryClient.getQueryData<GetFeaturedBetsResponse>(featuredBetsKeys.all);

    const leagueFeaturedBets =
        featuredBetsResponse?.data?.filter((featuredBet: FeaturedBet) => isLeagueIdMatching(featuredBet, leagueId)) ||
        [];

    return {
        data: leagueFeaturedBets,
    };
};

/**
 * Fetches featured bets for league hub
 */
export const useFeaturedBetsForLeague = (leagueId: string, enabled?: boolean) => {
    const queryClient = useQueryClient();
    const query = useQuery(featuredBetsKeys.featured(leagueId), () => getFeaturedBets(leagueId), {
        enabled,
        staleTime: 500, // prevent double fetching on initial load
        select: data => data.data.filter(bet => bet.bet_type !== 'SGP' && bet.bet_type !== 'SGP+'),
        placeholderData: getPlaceholderDataForLeague(queryClient, leagueId),
    });

    return query;
};
